import { Container, Paper, Typography } from "@mui/material";
import styled from "styled-components";

export const SContainer = styled(Container)`
    &&{
        padding:0px;
    }
`;

export const SPaper = styled(Paper)`
&&{
    border-radius:10px;
    background:var(--blue-sixth);
    padding:31px;
    min-height:314px;
    border: 2px solid transparent;
    &:hover{
        border:2px solid var(--white);
        transition:border 0.3s ease-in-out
    }
    @media (max-width: 768px) {
        border:2px solid var(--white);
    }
}
`;

export const STypographyTitle = styled(Typography)`
    &&{
        font-size:26px;
        font-weight: 400;
        font-family: Poppins Regular;
        color:var(--green-third);
        margin-bottom: 32px;
    }
`;

export const STypographyText = styled(Typography)`
    &&{
        font-size:16px;
        font-weight: 400;
        font-family: Poppins Regular;
        color:var(--white);
    }
`;