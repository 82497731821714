import React from "react";
import {
  STypographyCard,
  SPaperCard,
  SButtonCard,
  SBoxCard,
  TypographyBox,
  ZETAGrid,
} from "../../style.jsx";
import { Grid, useMediaQuery } from "@mui/material";

import CardMobile from "../cardmobile/Mobile.jsx";
import { Link } from "react-router-dom";

import CardIm1 from "../../../../assets/images/imagens/portos.png";
import CardIm2 from "../../../../assets/images/imagens/transitarios.png";
import CardIm3 from "../../../../assets/images/imagens/agentes_navegacao.png";
import CardIm4 from "../../../../assets/images/imagens/operadores_portuarios.png";
import CardIm5 from "../../../../assets/images/imagens/operadores_logisticos.png";
import CardIm6 from "../../../../assets/images/imagens/empresas.png";
import CardIm7 from "../../../../assets/images/imagens/setor_publico.png";

function Card() {
  const isMobile = useMediaQuery("(max-width: 900px)");

  let Pretext = '{"Card":[' +
  `{"slic2":"0","slic":"3","sx":[{"xs":"0px","sm":"0px","lg":"0px"},{"xs":"0px","sm":"50px","lg":"90px"},{"xs":"0px","sm":"0px","lg":"180px"}] },` +
  `{"slic2":"3","slic":"6","sx":[{"xs":"0px","sm":"50px","lg":"0px"},{"xs":"0px","sm":"0px","lg":"90px"},{"xs":"0px","sm":"50px","lg":"180px"}] },` +
  `{"slic2":"6","slic":"9","sx":[{"xs":"0px","sm":"0px","lg":"0px"},{"xs":"0px","sm":"50px","lg":"90px"},{"xs":"0px","sm":"0px","lg":"180px"}] }]}`;

  const PreData = JSON.parse(Pretext);

  let text = '{"Card":[' +
  `{"img":"${CardIm1}","name":"Portos","text":"O forte crescimento deste setor leva a novas necessidades de adaptação. Mais do que nunca é necessário criar sistemas que suportem a ligação destes setores para que os mesmo continuem a crescer. Todos os Portos que não se tornem em SmartPorts serão lentamente deixados para trás.","cont":"0","lin":"https://canow.com.br/","fill":"" },` +
  `{"img":"${CardIm2}","name":"Transitários","text":"Uma solução que se foca em otimizar todos os processos transitários. Desde de reservas online, Tracking, integração com transportadoras, gestão de taxas e faturação automática e muito mais.","cont":"1","lin":"https://jcanao.com/transitarios","fill":"" },` +
  `{"img":"${CardIm3}","name":"Agentes Navegação","text":"Somos uma bússola para soluções marítimas, potencializando as operações dos Agentes de Navegação e superando desafios. Junte-se a nós na rota do sucesso!","cont":"2","lin":"https://jcanao.com/agentesnavegacao","fill":"" },` +
  `{"img":"${CardIm4}","name":"Operadores Portuários","text":"Descubra a solução personalizada que otimizará suas operações portuárias. Da administração estratégica ao controle de carga, elevamos seu terminal à excelência operacional.","cont":"0","lin":"https://jcanao.com/operadoresportuarios","fill":"" },` +
  `{"img":"${CardIm5}","name":"Operadores Logísticos","text":"Explore a solução sob medida que irá aprimorar suas operações logísticas. Desde a gestão estratégica até o controle de inventário, nós elevamos o seu operador logístico à excelência operacional.","cont":"1","lin":"https://jcanao.com/operadoreslogisticos","fill":"" },` +
  `{"img":"${CardIm6}","name":"Empresas","text":"Controle o seu negócio com eficiência através da nossa solução, desenhada para simplificar processos e automatizar tarefas. Com funcionalidades diferenciadoras, contribui para uma gestão eficaz, transmitindo confiança aos clientes e fornecedores.","cont":"2","lin":"http://jcanao.com/","fill":"" },` +
  `{"img":"${CardIm7}","name":"Setor Público","text":"O setor público está em constante evolução, e as administrações públicas precisam de se adaptar às novas exigências. As nossas soluções ajudam as organizações públicas a modernizar a sua administração, tornando-a mais eficiente, eficaz e transparente.","cont":"0","lin":"https://jcanao.com/setorpublico","fill":"" },` +
  `{"img":"","name":"","text":"","cont":"1","fill":"fill" },` +
  `{"img":"","name":"","text":"","cont":"2","fill":"fill" }]}`;

  const Data = JSON.parse(text);

  if (isMobile) {
    return <CardMobile />;
  }
  
  return (
    <>
    
      <Grid
        container
        item
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "start",
          height: "auto",
        }}
      >
      {Object.keys(PreData.Card).map((key, index) => (
      <>
      {Object.keys(Data.Card).slice(PreData.Card[key].slic2,PreData.Card[key].slic).map((key2, subIndex) => (
        <ZETAGrid key={subIndex} container item xs={12} justifyContent={"center"} marginTopSm={PreData.Card[key].sx[Data.Card[key2].cont].sm}  marginTopLg={PreData.Card[key].sx[Data.Card[key2].cont].lg} >
          <SPaperCard className={Data.Card[key2].fill} style={{marginLeft: "50px"}} sx={{ background: `url(${Data.Card[key2].img})` }} >
            <SBoxCard>
              <TypographyBox sx={{ transform: "rotate(-90deg)" }}>
              {Data.Card[key2].name}
              </TypographyBox>
            </SBoxCard>
            <STypographyCard>
            {Data.Card[key2].text}
            </STypographyCard>
            <Link style={{ textDecoration: "none" }} to={Data.Card[key2].lin} target="_blank" >
              <SButtonCard disableRipple>Saber Mais</SButtonCard>
            </Link>
          </SPaperCard>
        </ZETAGrid>
      ))}
      </>
      ))}
      </Grid>
      
    </>
  );
}

export default Card;
