
import { Button, MenuItem } from "@mui/material";
import styled from 'styled-components';

export const SButton = styled(Button)`
    &&{
        width: 54px;
        height: 40px;
        border: 2px solid var(--white);
        color: var(--white);
        font-size: 20px;
        font-family: Poppins Regular;
        padding: 0px;
        min-width: 54px;
    }
`;

export const SMenuItem = styled(MenuItem)`
    &&{
        color: var(--blue);
    }
`;