import { SDivider, STypography } from "./style.js";
import React from "react";

import { Container, Grid } from "@mui/material";
 
import { alpha } from "@mui/system";

import Cards from "../../specializedsolutions/components/cardsolution/card.jsx";
import BG from "../../../assets/images/consult.png";
 
export default function Section2() {
  
  return (
    <>
      <Container
        maxWidth={false}
        disableGutters 
        style={{overflow: "hidden"}}
      >
        <Grid container item xs={12}>
          <img src={BG} alt="" style={{zIndex: -1, position: "absolute", width: "100%"}}/>
        </Grid>
        
        <Container
          maxWidth="xl"
          style={{zIndex: -1}}
          sx={{
            background: alpha("#fff", 0.16),
            paddingTop: { xs: "40px", lg: "150px" },
            paddingBottom: { xs: "64px", lg: "0" },
          }}
        >
          <Grid container item sx={{ marginTop: { xs: "128px", lg: "0" } }} style={{maxHeight: "fit-content"}}>
            <Grid item xs align="center">
              <STypography>Soluções</STypography>
              <SDivider />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={{
              marginTop: { xs: "32px", lg: "150px" },
              height: "auto",
            }}
          >
            <Grid container item xs={12} sx={{justifyContent:{ xs:"start", md:"center"} }} style={{zIndex: 1}}>
              <Cards />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <div style={{position: "absolute", zIndex: 0, marginTop: "-120px", minHeight:"600px", minWidth: "100%", background: "linear-gradient(0deg, rgba(40,109,255,1) 0%, rgba(40,109,255,1) 80%, rgba(255,255,255,0) 100%)"}}/>
    </>
  );
}
