import {
  Box,
  Container,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const SContainer = styled(Box)`
  && {
    
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-height: 108px;
    justify-content: center;
    position: relative;
    background: rgba(11, 62, 99, 0.2) 0% 0% no-repeat padding-box;
    @media (max-width: 1099.5px) {
      background: transparent;
    }
    padding: 0px;
  }
`;

export const SContainer2 = styled(Container)`
  && {
    position: fixed;
    top: 0;
    z-index: 5;
    @media (max-width: 1100px) {
      background: transparent;
    }
    padding: 0px;
  }
`;

export const SListItemButtonTitle = styled(ListItemButton)`
  && {
    color: var(--green);
    text-align: left;
    width: 100%;
    max-height: 100vh;
    & .MuiListItemButton-root {
      font-size: 36px;
      font-weight: 500;
      font-family: Poppins Medium;
      width: 100%;
    }
  }
`;

export const SPaper = styled(Paper)`
  && {
    border-radius: 0px;
    height:183px;
    box-sizing: border-box;
    padding: 16px;
    width: 256px;
    background: rgba(11, 62, 99, 0.2);
    backdrop-filter: blur(30px);
    border: 2px solid transparent;
    -webkit-backdrop-filter: blur(30px);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    transition: all 0.3s ease-in-out;
    &:hover {
      border: 2px solid var(--green-third);
    }
  }
`;

export const STypography = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
  }
`;

export const STypographyMobile = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
    text-align: left;
    margin-top: 16px;
    padding: 0px 16px;
  }
`;

export const STypographyMobileTitle = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins SemiBold;
    color: var(--green);
    text-align: left;
    margin-top: 33px;
    padding: 0px 16px;
  }
`;


export const SDiv = styled.div`
  && {
    position: fixed;
    
    width: 100%;
  }
`;