import { Box, Container } from "@mui/material";
import React from "react";
import { SBox, STypographyText, STypographyTitle, SButton3 } from "./style.jsx";
import BG from "../../../../assets/images/big_reworked_lighter.jpg";
import Arrow from "../../../../assets/icons/arrow.svg";
import BackButton from "../backbutton/BackButton.jsx";

function Main() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Container
      maxWidth="false"
      sx={{ background: `url(${BG})`}}
      disableGutters
    >
      <Container maxWidth="lg" sx={{position:'relative'}}>
      <BackButton />
        <SBox>
          <STypographyTitle>O que são Cookies?</STypographyTitle>
          <STypographyText>
            Os cookies são pequenos ficheiros de texto enviados para o seu computador ou dispositivo móvel quando visita websites. Os cookies ajudam os websites a armazenar e recuperar informação sobre os seus hábitos de navegação por forma a personalizar a navegação de acordo com as suas preferências.
          </STypographyText>
          <STypographyTitle>Que tipos de cookies utiliza o nosso website?</STypographyTitle>
          <STypographyText>
            Cookies de sessão: são cookies temporárias que permanecem em arquivo de cookies do navegador até que este abandone a página web, pelo que não fica qualquer registo no disco rígido do utilizador. A Informação recolhida por estes cookies servem para analisar padrões de tráfego do site. Em última análise, isso permite-nos melhorar o conteúdo e melhorar a usabilidade do site.
          </STypographyText>
          <STypographyText>
            Cookies de análise: São aqueles que bem tratados por nós ou por terceiros, nos permitem quantificar o número de utilizadores e realizar a medição e análise estatística de como os utilizadores usam o serviço oferecido. Examinamos a sua navegação no nosso site, com o objetivo de melhorar o fornecimento dos serviços que lhe disponibilizamos.
          </STypographyText>
          <STypographyText>
            Cookies publicitárias: São aqueles que tratadas por nós ou por terceiros, nos permitem gerir de uma forma mais eficaz a oferta de espaços publicitários que existem no site, podendo analisar os seus hábitos de navegação e mostrar-lhe publicidade relacionada com o seu perfil de navegação.
          </STypographyText>
          <STypographyTitle>Quais são os cookies que utilizamos no nosso site?</STypographyTitle>
          <STypographyText>
            <p><text style={{ fontWeight: "bold" }}>Cookies Próprios:</text><br />
            - Cookies de sessão;<br />
            - Armazenam a informação de categoria para mostrar as páginas de forma mais rápida;<br />
            - Indicam se está atualmente logado no site;<br />
            - ID's de sessão no servidor;<br />
            - Informações sobre pesquisas;<br />
            - Indicam se o utilizador autoriza o uso de cookies.</p>
            <p><text style={{ fontWeight: "bold" }}>Cookies de Terceiros:</text><br />
            - Utilizados para distinguir utilizadores e sessões;<br />
            - Utilizadas para determinar novas visitas, sessões;<br />
            - Armazenam a fonte de tráfego para explicar como o utilizador chega ao site;<br />
            - Utilizam-se para identificar utilizadores e prevenir usos fraudulentos de dados de utilizadores e proteger dados de utilizadores de terceiros;<br />
            - Utilizadas para personalizar publicidade em redes de Publisher;<br />
            - Permitem ao site recordar preferências de navegação, idioma, região.</p>
            <p><text style={{ fontWeight: "bold" }}>Outros:</text><br />
            - Analíticas web e vídeos;<br />
            - Determina que servidor mostra a informação ao utilizador;<br />
            - Segue as sessões ativas;<br />
            - Determina se as cookies estão ativas;<br />
            - Mede o tempo de resposta dos utilizadores;<br />
            - Identifica quantas vezes nos visitou um utilizador,<br />
            - Permitem saber se é a primeira visita ao site ou não.</p>
          </STypographyText>
        </SBox>
        <Box
          maxWidth="lg"
          sx={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <SButton3 disableRipple={true} onClick={handleClick}>
            <img
              src={Arrow}
              alt=""
              width={20}
              style={{ transform: "rotate(180deg)" }}
            ></img>
          </SButton3>
        </Box>
      </Container>
    </Container>
  );
}

export default Main;
