import { Button } from "@mui/material";
import styled from "styled-components";

export const SImg = styled.img`
  transform: rotate(90deg);
  filter: invert(60%) sepia(63%) saturate(3000%) hue-rotate(215deg)
    brightness(103%) contrast(80%);
  width: 16px;
`;

export const SButtonBack = styled(Button)`
  && {
    position: sticky;
    top: -2.8%;
    transform: translateY(115%);
    left: 1650px;
    width: 122px;
    height: 46px;
    background: var(--white);
    border-radius: 5px;
    border: none;
    color: var(--blue);
    text-transform: none;
    font-weight: 500;
    font-family: Poppins Medium;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    &:hover {
      background: var(--green-third);
    }
    @media (max-width: 768px) {
      position: sticky;
      left: 16px;
      top: -2.8%;
    }
  }
`;
