import { Grid} from '@mui/material'
import React from 'react';
import {SContainer, SPaper, STypographyTitle,STypographyText} from './style.jsx';
import data from './data.json';

function Box() {
  return (
    <SContainer maxWidth="lg">
     <Grid container sx={{display:'flex', justifyContent:'space-between', gap:'33px'}}>
      {data.Data.map((item, index) => (
        index < 3 &&(
        <Grid item xs={12} sm={12} md={5} lg key={index}>
              <SPaper elevation={0}>
                <STypographyTitle>{item.Title}</STypographyTitle>
                <STypographyText>{item.Text}</STypographyText>
              </SPaper>
            </Grid>
      )
      ))}
    </Grid>
    <Grid container sx={{display:'flex', justifyContent:'space-between', gap:'33px', marginTop:'33px'}}>
      {data.Data.map((item, index) => (
        index > 2 &&(
        <Grid item xs={12} sm={12} md={5} lg key={index}>
          <SPaper elevation={0}>
            <STypographyTitle>{item.Title}</STypographyTitle>
            <STypographyText>{item.Text}</STypographyText>
          </SPaper>
        </Grid>
      )
      ))}
    </Grid> 
  </SContainer>
  )
}

export default Box