import styled from "styled-components";
import Bg from "../../assets/images/cover-consult.jpg";
import Bg2 from "../../assets/images/big_reworked_lighter.jpg";
import { Button, Container, Paper, Typography } from "@mui/material";

export const SContainer = styled(Container)`
  background: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
`;

export const SContainer2 = styled(Container)`
  && {
    background: url(${Bg2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    margin-bottom: 64px;
    padding: 8px;
    @media (max-width: 768px) {
      margin-top: calc(64px - 16px);
      position: relative;
    }
  }
`;

export const STypography = styled(Typography)`
  && {
    color: var(--white);
    font-size: 76px;
    font-weight: 400;
    font-family: Poppins Regular;
    width: 511px;
    @media (max-width: 600px) {
      font-size: 48px;
    }
  }
`;

export const STypographyTitle = styled(Typography)`
  && {
    font-size: 32px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--green-third);
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
    max-width: 500px;
  }
`;

export const SButton = styled(Button)`
  && {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 3px 6px var(--gray);
    background: var(--white) padding-box;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--blue-seventh);
    text-transform: none;
    float: bottom;
    padding: 12px 32px;
    &:hover {
      background: var(--green-third);
    }
    width: 146px;
  }
`;

export const SButton2 = styled(Button)`
  && {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 3px 6px var(--gray);
    background: var(--white) padding-box;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--blue-seventh);
    text-transform: none;
    margin-top: 64px;
    margin-bottom: 250px;
    padding: 12px 32px;
    &:hover {
      background: var(--green-third);
    }
  }
`;

export const SButton3 = styled(Button)`
  && {
    border: 3px solid var(--green-third);
    border-radius: 8px;
    background: rgba(var(--green-third));
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    margin-bottom: 33px;
    &:hover {
      background: var(--white);
    }
  }
`;

export const SButtonDownload = styled(Button)`
  && {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    border: 1px solid var(--white);
    width: 113px;
  }
`;

export const STextDownload = styled.a`
  font-weight: 500;
  font-family: Poppins Medium;
  font-size: 16px;
  color: var(--white);
  text-transform: none;
  text-decoration: none;
`;

export const SButtonBack = styled(Button)`
  && {
    position: sticky;
    top: 50%;
    transform: translateY(115%);
    left: 1650px;
    width: 122px;
    height: 46px;
    background: var(--white);
    border-radius: 5px;
    border: none;
    color: var(--blue);
    text-transform: none;
    font-weight: 500;
    font-family: Poppins Medium;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    &:hover {
      background: var(--green-third);
    }
    @media (max-width: 768px) {
      position: absolute;
      left: 16px;
      top: -1.8%;
    }
  }
`;

export const SPaper = styled(Paper)`
  && {
    border-radius: 10px;
    border: 2px solid var(--green-third);
    background: var(--blue-sixth);
    padding: 64px;
    box-shadow: 0px 0px 6px var(--green-third);
    min-height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 600px) {
      gap: 16px;
      padding: 32px;
    }
  }
`;

export const SPaper2 = styled(Paper)`
  && {
    border-radius: 10px;
    background: var(--blue-sixth);
    padding: 32px;
    min-height: 314px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid var(--white);
      transition: border 0.3s ease-in-out;
    }
    @media (max-width: 768px) {
      border: 2px solid var(--white);
    }
  }
`;

export const STypographyTitle2 = styled(Typography)`
  && {
    font-size: 26px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--green-third);
    margin-bottom: 32px;
  }
`;

export const STypographyText2 = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
  }
`;

export const STypographyDownload = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--white);
  }
`;

export const STypographyDownload2 = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;

export const SImg = styled.img`
  transform: rotate(90deg);
  filter: invert(60%) sepia(63%) saturate(3000%) hue-rotate(215deg)
    brightness(103%) contrast(80%);
  width: 16px;
`;
