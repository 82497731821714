import { Typography } from "@mui/material";
import styled from "styled-components";
import Button from "@mui/material/Button";

export const STypographyTitle = styled(Typography).attrs({ variant: "p" })`
  color: var(--white);
  font-size: 26px;
  font-family: Poppins Semibold;
  text-align: left;
  width: 65%;
`;

export const STypographyText = styled(Typography).attrs({ variant: "p" })`
  color: var(--white);
  font-size: 16px;
  font-family: Poppins Regular;
  text-align: left;
  width: 65%;
`;

export const SButton = styled(Button)`
  && {
    max-width: 122px;
    width: 100%;
    height: 46px;
    padding: 0;
    background: var(--white);
    border-radius: 5px;
    box-shadow: 0px 1px 3px var(--green);
    color: var(--blue);
    font-size: 20px;
    font-family: Poppins Medium;
    border: none;
    cursor: pointer;
    text-transform: none;
    &:hover {
      background: var(--green);
      color: var(--white);
    }
  }
`;

export const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 900px) {
    display: none;
  }
`;
