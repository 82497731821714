import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import jcanaoWhite from "../../assets/images/jcanao_white.svg";
import HeaderButton from "./components/headerButton/Button.jsx";
import HeaderButtonLang from "./components/headerButtonLang/Button.jsx";
import {
  SContainer,
  SContainer2,
  SListItemButtonTitle,
  STypographyMobileTitle,
  STypographyMobile,
  SDiv,
} from "./style.jsx";
import MenuIcon from "../../assets/icons/menu.svg";
import MenuCloseIcon from "../../assets/icons/plus-gl.svg";
import DropDown from "./components/dropdownmenu/MainDropMenu.jsx";
import ExpandLessRounded from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded";
import Collapse from "@mui/material/Collapse";

function Header() {
  // Array com nome dos botões
  const buttonData = ["Soluções", "Sobre Nós", "Carreiras"];

  // Estado do Menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Funções do Menu
  const handleMenuClick = () => {
    setIsMenuOpen(true);
  };

  const handleModalClose = () => {
    setIsMenuOpen(false);
  };

  // Estado do Ecrã Mobile
  const [isMobileView, setIsMobileView] = useState(false);

  //Funções do Ecrã Mobile
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 1100);
  };

  // Listener de evento para as alterações do tamanho do ecrã
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeDropdown, setActiveDropdown] = useState(null);

  const openDropdown = (index) => {
    setActiveDropdown(index);
  };

  const handleButtonClick = (index) => {
    if (activeDropdown === index) {
      closeDropdown();
    } else {
      openDropdown(index);
    }

    const clickedButton = buttonData[index];

    if (clickedButton === "Sobre Nós") {
      window.open("aboutUs", "_self");
      closeDropdown();
    }
    if (clickedButton === "Carreiras") {
      window.open("carreiras", "_self");
      closeDropdown();
    }
  };

  const handleClickIN = () => {
      window.open("/", "_self");
      closeDropdown();
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
  };

  const [openCollapse2, setOpenCollapse2] = useState(false);

  const handleClick2 = () => {
    setOpenCollapse2(!openCollapse2);
  };




  const languages = ["PT", "EN", "DE"];

 

  const windowSize = useState(window.innerHeight);

  console.log(windowSize);

  return (
    <SContainer2
      maxWidth="false"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <SContainer style={{background: "var(--blue)", boxShadow: "0px 0px 1px 2px var(--green2)"}}>
        <Grid container maxWidth="lg" sx={{ height: "80px" }}>
          {isMobileView ? (
            <Container disableGutters>
              <Grid item onClick={handleClickIN} style={{cursor: "pointer"}}>
                <img src={jcanaoWhite} alt="" height={70}/>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  position: "fixed",
                  top: "5px",
                  right: "5px",
                }}
              >
                <IconButton onClick={handleMenuClick}>
                  <img src={MenuIcon}/>
                </IconButton>
              </Grid>
            </Container>
          ) : (
            <>
              <Grid item onClick={handleClickIN} sx={{ display: "flex", alignItems: "center", justifyContent: "start", }}  style={{cursor: "pointer"}}>
                <img src={jcanaoWhite} alt="" height={70} />
              </Grid>
              <Grid
                item
                flexGrow={1}
                sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap: "16px", marginLeft: "60px", }}
              >
                {buttonData.map((buttonLabel, index) => (
                  <HeaderButton key={index} onClick={() => handleButtonClick(index)} isActive={activeDropdown === index} >
                    {buttonLabel}
                  </HeaderButton>
                ))}
              </Grid>
              <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "end", position: "relative", marginLeft: "30px", width: "60px" }} >
                {/*
                <HeaderButtonLang />
                */}
              </Grid>
            </>
          )}
        </Grid>







        
        <Modal open={isMenuOpen} onClose={handleModalClose}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--blue)",
              width: "100%",
              height: "100%",
              maxHeight: "100vh",
              overflowY: "auto",
            }}
          >
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item  onClick={handleClickIN}>
                <img src={jcanaoWhite} alt="" height={70} />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleModalClose}
                  sx={{ marginRight: "12px" }}
                >
                  <img
                    style={{ transform: "rotate(45deg)" }}
                    src={MenuCloseIcon}
                    alt=""
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container width={"100%"}>
              <List sx={{ width: "100%" }}>
                <SListItemButtonTitle onClick={handleClick2}>
                  <ListItemText
                    primary="Soluções"
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins Medium",
                        fontSize: "36px",
                        color: "var(--green)",
                      },
                    }}
                  />
                  {openCollapse2 ? (
                    <ExpandLessRounded sx={{ fontSize: "54px" }} />
                  ) : (
                    <ExpandMoreRounded sx={{ fontSize: "54px" }} />
                  )}
                </SListItemButtonTitle>


                <Collapse in={openCollapse2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton component="a" href="https://canow.com.br/" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Portos"
                      />
                    </ListItemButton>
                    <ListItemButton component="a" href="https://jcanao.com/transitarios" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Transitários"
                      />
                    </ListItemButton>
                    <ListItemButton component="a" href="https://jcanao.com/agentesnavegacao" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Agentes de Navegação"
                      />
                    </ListItemButton>
                    <ListItemButton component="a" href="https://jcanao.com/operadoresportuarios" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Operadores Portuários"
                      />
                    </ListItemButton>
                    <ListItemButton component="a" href="https://jcanao.com/operadoreslogisticos" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Operadores Logísticos"
                      />
                    </ListItemButton>
                    <ListItemButton component="a" href="http://jcanao.com/" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Empresas"
                      />
                    </ListItemButton>
                    <ListItemButton component="a" href="https://jcanao.com/setorpublico" sx={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontFamily: "Poppins Regular",
                            fontSize: "20px",
                            color: "var(--white)",
                          },
                        }}
                        primary="Setor Público"
                      />
                    </ListItemButton>
                  </List>
                </Collapse>

                <a href="/aboutUs" style={{textDecoration:"none"}}>
                <ListItemButton>
                  <ListItemText
                    primary="Sobre Nós"
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins Medium",
                        fontSize: "36px",
                        color: "var(--green)",
                      },
                    }}
                  />
                </ListItemButton>
                </a>
                <a href="/carreiras" style={{textDecoration:"none"}}>
                <ListItemButton>
                  <ListItemText
                    primary="Carreiras"
                    primaryTypographyProps={{
                      style: {
                        fontFamily: "Poppins Medium",
                        fontSize: "36px",
                        color: "var(--green)",
                      },
                    }}
                  />
                </ListItemButton>
                </a>
                <Divider
                  sx={{
                    width: "100%",
                    height: "2px",
                    marginTop: "33px",
                    backgroundColor: "var(--white)",
                  }}
                ></Divider>
              </List>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <STypographyMobileTitle>Contactos</STypographyMobileTitle>
                <STypographyMobile>+351 258 800 600</STypographyMobile>
                <STypographyMobile>+351 924 453 260</STypographyMobile>
                <STypographyMobile>jcanao@jcanao.pt</STypographyMobile>
                <STypographyMobile>comercial@jcanao.pt</STypographyMobile>
                <Box component="form" autoComplete="off">
                  <TextField
                    style={{display: "none"}}
                    select
                    defaultValue="PT"
                    SelectProps={{
                      disableunderline: true,
                      IconComponent: null,
                      style: {
                        fontFamily: "Poppins Regular",
                        fontSize: "20px",
                        color: "var(--white)",
                        padding: "0px",
                        margin: "0 16px",
                        marginTop: "64px",
                        width: "max-content",
                        border: "1px solid var(--white)",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    {languages.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Modal>
      </SContainer>
      {activeDropdown !== null && (
        <>
          <SDiv style={{ height: `${windowSize[0]}px`}} onClick={closeDropdown}/>
          <DropDown
            closeDropdown={closeDropdown}
            activeDropdown={activeDropdown}
          />
        </>
      )}
    </SContainer2>
  );
}

export default Header;
