import React, { useEffect, useState } from "react";
import { SButtonContact, SImg3, SImg4 } from "../style.jsx";
import BottomRightGreen from "../../../assets/images/bottom_right_green.svg";
import TopLeftGreen from "../../../assets/images/top_left_green.svg";
import Modalcontact from "./ContactFormModal";

function Contactbtn() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <SButtonContact disableRipple={true} onClick={handleOpen}>
        <SImg3 src={TopLeftGreen} atl="" />
        Contactar
        <SImg4 src={BottomRightGreen} atl="" />
      </SButtonContact>
      {openModal && (
        <Modalcontact openModal={openModal} handleClose={handleClose} />
      )}
    </>
  );
}

export default Contactbtn;
