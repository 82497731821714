import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import {
  STypography,
  STypographyYear,
  STypographyTitle,
  STypographyText,
  SBox,
  TContainer,
  T2Container,
  HGrid,
} from "./style.jsx";
import Bgtimeline from "../../assets/images/stair.jpg";
import Oxygeniu from "../../assets/images/oxigeniu_white.svg";

function Timeline() {
  return (
    <>
    <Container
      maxWidth="false"
      sx={{ marginTop: { xs: "128px", lg: "384px" } }}
      disableGutters
    >
      <Grid container justifyContent={"center"}>
        <Grid container item xs={12} sm alignItems={"center"} >
          <Grid container item lg={12} rowGap={"10px"} alignItems={"center"} justifyContent={"end"} style={{maxHeight: "48px"}}>
            <Box
              sx={{
                display: "block",
                height: "6px",
                background: " #00FFD4 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00FFD4",
                borderRadius: "8px",
                opacity: "1",
                width: "50px",
              }}
            ></Box>
            <Box
              sx={{
                display: "block",
                height: "6px",
                background: " #00FFD4 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00FFD4",
                borderRadius: "8px",
                opacity: "1",
                width: "100%",
              }}
            ></Box>
            <Box
              sx={{
                display: "block",
                height: "6px",
                background: " #00FFD4 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00FFD4",
                borderRadius: "8px",
                opacity: "1",
                width: "50px",
              }}
            ></Box>
          </Grid>
        </Grid>
        <HGrid container item xs={12} alignItems={"center"} justifyContent={"center"} sx={{ textAlign: "center", padding: "0 30px" }} >
          <STypography align="center">A Nossa História</STypography>
        </HGrid>
        <Grid container item xs={12} sm alignItems={"center"} >
          <Grid container item lg={12} rowGap={"10px"} alignItems={"center"} justifyContent={"start"} style={{maxHeight: "48px"}}>
            <Box
              sx={{
                display: "block",
                height: "6px",
                background: " #00FFD4 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00FFD4",
                borderRadius: "8px",
                opacity: "1",
                width: "50px",
              }}
            ></Box>
            <Box
              sx={{
                display: "block",
                height: "6px",
                background: " #00FFD4 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00FFD4",
                borderRadius: "8px",
                opacity: "1",
                width: "100%",
              }}
            ></Box>
            <Box
              sx={{
                display: "block",
                height: "6px",
                background: " #00FFD4 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00FFD4",
                borderRadius: "8px",
                opacity: "1",
                width: "50px",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Grid>

      <TContainer maxWidth="false" disableGutters>
        <Container maxWidth="xl">
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={4}
              display={"flex"}
              sx={{
                justifyContent: { xs: "start", md: "center" },
              }}
            >
              <STypographyYear>1987</STypographyYear>
            </Grid>
            <Grid container item xs={3}>
              <Grid item xs={12}></Grid>
              <Grid
                item
                xs={12}
                sx={{
                  borderTop: { md: "none", lg: "1px solid white" },
                  borderRight: { md: "none", lg: "1px solid white" },
                  "::before": {
                    content: "''",
                    borderRadius: "50%",
                    backgroundColor: "#00FFD4",
                    boxShadow: "0px 0px 10px 0px #00FFD4",
                    position: "absolute",
                    width: "66px",
                    height: "66px",
                    marginTop: "-35px",
                    marginLeft: "-40px",
                    display: { xs: "none", lg: "block" },
                  },
                }}
              ></Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={0} md={0} lg={4}></Grid>

            <Grid container item xs={12} md={10} lg={6}>
              <Grid item xs={6}>
                <STypographyTitle
                  sx={{
                    borderRight: { md: "none", lg: "1px solid white" },
                    paddingBottom: "0px !important",
                  }}
                >
                  O início
                </STypographyTitle>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ borderBottom: { md: "none", lg: "1px solid white" } }}
              ></Grid>

              <Grid
                item
                xs={12}
                sx={{
                  borderRight: { md: "none", lg: "1px solid white" },
                  paddingRight: "40px",
                  paddingTop: "40px",
                }}
              >
                <STypographyText>
                  A nossa rota iniciou-se nas redes e sistemas informáticos, com
                  o primeiro software de ERP criado em base de MS-DOS e que
                  ainda acompanha a nossa evolução ao longo do tempo. Devido ao
                  mesmo expandimos lentamente para outras áreas, como
                  Administrações Portuárias, Instituições Públicas (Freguesias e
                  Municípios) e Privadas (Empresas).
                </STypographyText>
              </Grid>
            </Grid>
            <Grid
              item
              xs={0}
              md={0}
              lg={2}
              sx={{ borderBottom: { md: "none", lg: "1px solid white" } }}
            ></Grid>
          </Grid>
        </Container>
      </TContainer>
      <TContainer maxWidth="false" disableGutters sx={{}}>
        <Container maxWidth="xl" sx={{}}>
          <Grid
            container
            item
            xs={12}
            sx={{
              borderRight: { md: "none", lg: "1px solid white" },
              display: "flex",
              justifyContent: "center",
              paddingTop: { xs: "64px", lg: "200px" },
              position: "relative",
              "::before": {
                content: "''",
                borderRadius: "50%",
                backgroundColor: "#00FFD4",
                boxShadow: "0px 0px 10px 0px #00FFD4",
                position: "absolute",
                width: "66px",
                height: "66px",
                right: "-33px",
                top: "50%",
                transform: "translateY(-50%)",
                display: { xs: "none", lg: "block" },
              },
            }}
          >
            <Grid item xs={0} md={0} lg={2}></Grid>
            <Grid container sx={{ position: "relative" }}>
              <Grid item xs={12} lg={2} position={"relative"}></Grid>
              <Grid item xs={12} lg={7} position={"relative"} sx={{}}>
                <STypographyTitle>Expansão dos mares</STypographyTitle>
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={{
                    display: { xs: "flex", lg: "none" },
                    justifyContent: "end",
                  }}
                >
                  <STypographyYear>2002</STypographyYear>
                </Grid>
                <Grid item xs={12} md={10} lg={6} position={"relative"} sx={{}}>
                  <STypographyText>
                    O sucesso nesta áreas levou à sua expansão e diversas
                    certificações de qualidade. Trazendo novas soluções modernas
                    (web) para Operadores Portuários, Logísticos, Transitários e
                    Agentes de Navegação com plataformas como a JUP (Janela
                    Única Portuária).
                    <br />
                    <br />
                    Com todo o conhecimento adquirido nestes anos, iniciamos um
                    serviço de Consultoria.
                  </STypographyText>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    justifyContent: "end",
                    borderBottom: { md: "none", lg: "1px solid white" },
                    paddingRight: { md: "none", lg: "60px" },
                  }}
                >
                  <STypographyYear>2002</STypographyYear>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </TContainer>
      <TContainer maxWidth="xl" sx={{}}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={0}
            lg={8}
            sx={{
              borderRight: { md: "none", lg: "1px solid white" },
              paddingTop: { xs: "64px", lg: "300px" },
            }}
          ></Grid>
          <Grid item xs={0} md={0} lg={4}></Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={0}
            md={0}
            lg={2.5}
            sx={{
              borderRight: { md: "none", lg: "1px solid white" },
              position: "relative",
              "::before": {
                content: "''",
                borderRadius: "50%",
                backgroundColor: "#00FFD4",
                boxShadow: "0px 0px 10px 0px #00FFD4",
                position: "absolute",
                width: "66px",
                height: "66px",
                right: "-33px",
                top: "21%",
                transform: "translateY(-50%)",
                display: { xs: "none", lg: "block" },
              },
            }}
          ></Grid>
          <Grid
            item
            xs={0}
            md={0}
            lg={1}
            sx={{ borderTop: { md: "none", lg: "1px solid white" } }}
          ></Grid>

          <Grid item xs={12} md={8} lg={6} position={"relative"}>
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                lg={6.5}
                sx={{
                  paddingTop: "20px",
                  borderTop: { md: "none", lg: "1px solid white" },
                }}
              ></Grid>
              <Grid item xs={12} lg={5.5} sx={{}}></Grid>
            </Grid>
            <Box display={"flex"}>
              <Grid
                item
                xs={12}
                lg={2}
                sx={{
                  marginRight: { lg: "33px" },
                }}
              >
                <STypographyYear
                  sx={{
                    transform: { xs: "rotate(0deg)", lg: "rotate(-90deg)" },
                    marginTop: { xs: "0px", lg: "145px" },
                    display: { xs: "block", lg: "none" },
                    //padding
                  }}
                >
                  2013
                </STypographyYear>
              </Grid>
              <Grid>
                <STypographyTitle sx={{ textAlign: { xs: "end", lg: "" } }}>
                  Uma nova era
                </STypographyTitle>
              </Grid>
            </Box>
            <Grid container>
              <Grid
                item
                xs={12}
                lg={2}
                sx={{
                  marginLeft: "30px",
                  paddingRight: "50px",
                  paddingTop: { lg: "160px", xs: "0px" },
                }}
              >
                <STypographyYear
                  sx={{
                    transform: "rotate(-90deg)",

                    display: { xs: "none", lg: "block" },
                  }}
                >
                  2013
                </STypographyYear>
              </Grid>
              <Grid item xs={12} lg={8}>
                <STypographyText>
                  Após vários anos a criar soluções com êxito, decidimos que
                  estava na altura de investir no que de melhor tinhamos e criar
                  um sistema capaz de criar soluções de forma rápida e eficaz
                  com a capacidade de se adaptar de forma ágil às necessidades
                  de cada negócio. E foi assim, que nasceu a Canow. Com a Canow
                  lançada, investimos ainda mais para que se afirma-se no
                  mercado, com dois projetos distintos. O projeto Cognita que
                  revolucionou a gestão e análise de dados em grande escala e o
                  projeto Excello que evoluiu os Parques de Contentores.
                </STypographyText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TContainer>
      <TContainer maxWidth="xl" disableGutters>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            lg={6} //maxWidth="xl"
            maxWidth="xl"
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                padding: { xs: "16px", lg: "0px" },
              }}
            >
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: { xs: "none", lg: "flex" },
                }}
              >
                <Grid item xs={0} lg={4}></Grid>
                <Grid
                  item
                  xs={6} //align to center
                  sx={{
                    borderBottom: "1px solid white",
                    paddingBottom: "60px",
                  }}
                >
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Grid item lg={5.3}></Grid>
                    <Grid
                      item
                      lg={4.7}
                      sx={{
                        borderTop: { md: "none", lg: "1px solid white" },
                        paddingTop: "60px",
                      }}
                    ></Grid>
                  </Grid>

                  <SBox>
                    <img width={300} src={Oxygeniu} alt="" />
                    <STypographyText>
                      Nesta epóca nasceu ainda a Oxi.geniu, um centro de
                      formação certificado com o objetivo de fomentar o setor
                      socioéconomico do país.
                    </STypographyText>
                  </SBox>
                </Grid>
                <Grid container item xs={0} lg={2}>
                  <Grid
                    item
                    xs={0}
                    lg={6}
                    sx={{
                      borderTop: "1px solid white",
                      borderRight: "1px solid white",
                      borderBottom: "1px solid white",
                    }}
                  ></Grid>
                  <Grid item xs={0} lg={6}></Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderRight: { md: "none", lg: "1px solid white" },
                    padding: "20px",
                  }}
                ></Grid>
              </Grid>

              <Grid
                container
                sx={{
                  position: "relative",
                  justifyContent: { xs: "start", md: "center", lg: "start" },
                }}
              >
                <Grid container item xs={12} lg={2}></Grid>

                <Grid container item xs={12} md={8} lg={10} position={"relative"}>
                  <Grid item xs={12}>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          borderTop: { md: "none", lg: "1px solid white" },
                          borderLeft: { md: "none", lg: "1px solid white" },
                        }}
                      ></Grid>
                      <Grid container item xs={10} sx={{}}>
                        <Grid item xs={12}>
                          <Grid
                            item
                            xs={0.5}
                            sx={{
                              borderTop: { md: "none", lg: "1px solid white" },
                            }}
                          ></Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingLeft: "20px" }}>
                          <STypographyTitle>O Futuro</STypographyTitle>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          borderLeft: { md: "none", lg: "1px solid white" },
                        }}
                      ></Grid>
                      <Grid item xs={9} sx={{ paddingLeft: "20px" }}>
                        <STypographyText>
                          Após uma epóca um bocado mais díficil para o
                          mundo/mercado, ganhamos ainda mais força para
                          desenvolver novas e melhores soluções, não deixando
                          ficar para trás as existentes.
                        </STypographyText>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        align: { xs: "left", lg: "right" },
                        position: "relative",
                      }}
                    >
                      <Grid container item xs={2}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            borderLeft: { md: "none", lg: "1px solid white" },
                            borderBottom: { md: "none", lg: "1px solid white" },
                            position: "relative",
                            "::before": {
                              content: "''",
                              borderRadius: "50%",
                              backgroundColor: "#00FFD4",
                              boxShadow: "0px 0px 10px 0px #00FFD4",
                              position: "absolute",
                              width: "66px",
                              height: "66px",
                              bottom: "0",
                              left: "40%",
                              transform: "translateY(50%)",
                              display: { xs: "none", lg: "block" },
                            },
                          }}
                        ></Grid>
                        <Grid item xs={12}></Grid>
                      </Grid>
                      <Grid item xs={10} sx={{ paddingLeft: "20px" }}>
                        <STypographyYear>2025</STypographyYear>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={6}>
            <Grid
              item
              xs={0}
              md={0}
              lg={12}
              sx={{ display: { xs: "none", lg: "block" } }}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  md: { display: "none" },
                }}
                src={Bgtimeline}
                alt=""
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                marginLeft: { xs: "0", md: "0", lg: "auto" },
                marginTop: { xs: "0px" },
                align: { xs: "left", lg: "right" },
                padding: { xs: "16px", lg: "0px" },
              }}
            >
              <Typography
                sx={{
                  color: "var(--green)",
                  fontSize: { xs: "20px", lg: "26px" },
                  fontFamily: { xs: "Poppins Regular", lg: "Poppins Semibold" },
                  marginTop: { xs: "46px", lg: "57px" },
                  width: { xs: "100%", lg: "580px" },
                }}
              >
                Temos como objetivo não reinventar a roda, mas torna-la ainda
                mais eficaz e já estamos a rolar para isso...
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TContainer>
      <Divider
        sx={{
          marginTop: "64px",
          backgroundColor: "white",
          height: "3px",
          display: { xs: "block", sm: "none" },
        }}
        variant="fullWidth"
      />
    </Container>


    <T2Container maxWidth={false} disableGutters >
        <Container maxWidth={false} disableGutters>
          <Grid container paddingTop={"100px"}>
            <Grid item xs={12} display={"flex"} sx={{ justifyContent: "center" }} >
              <STypographyYear>1987</STypographyYear>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "center" }} >
              <Grid item xs={11} lg={10}>
                <STypographyTitle sx={{paddingBottom: "0px !important" }}>
                  O início
                </STypographyTitle>
              </Grid>
              <Grid item xs={11} lg={10} sx={{ paddingRight: "40px", paddingTop: "40px" }} >
                <STypographyText>
                  A nossa rota iniciou-se nas redes e sistemas informáticos, com
                  o primeiro software de ERP criado em base de MS-DOS e que
                  ainda acompanha a nossa evolução ao longo do tempo. Devido ao
                  mesmo expandimos lentamente para outras áreas, como
                  Administrações Portuárias, Instituições Públicas (Freguesias e
                  Municípios) e Privadas (Empresas).
                </STypographyText>
              </Grid>
            </Grid>
          <Grid container item xs={12} >
          <Grid item xs={12} sx={{ display: { xs: "flex" }, justifyContent: "center", }} >
            <STypographyYear marginTop={"20px"} >2002</STypographyYear>
          </Grid>
          <Grid container sx={{ display: { xs: "flex" }, justifyContent: "center", }}>
            <Grid item xs={11} lg={10} position={"relative"} sx={{}}>
              <STypographyTitle>Expansão dos mares</STypographyTitle>
            </Grid>
            <Grid container item xs={11} lg={10} sx={{ display: "flex", justifyContent: "right" }} >
              <STypographyText>
                O sucesso nesta áreas levou à sua expansão e diversas
                certificações de qualidade. Trazendo novas soluções modernas
                (web) para Operadores Portuários, Logísticos, Transitários e
                Agentes de Navegação com plataformas como a JUP (Janela
                Única Portuária).
                <br />
                <br />
                Com todo o conhecimento adquirido nestes anos, iniciamos um
                serviço de Consultoria.
              </STypographyText>
            </Grid>
          </Grid>
          </Grid>
          <Grid container xs={12} sx={{ display: "flex", justifyContent: "center", }} >
              <STypographyYear marginTop={"20px"}>2013</STypographyYear>
          <Grid/>
          <Grid container sx={{ display: { xs: "flex" }, justifyContent: "center", }}>
            <Grid item xs={11} lg={10} position={"relative"} sx={{}}>
              <STypographyTitle>Uma nova era</STypographyTitle>
            </Grid>
            <Grid container item xs={11} lg={10} sx={{ display: "flex", justifyContent: "right" }} >
              <STypographyText>
                Após vários anos a criar soluções com êxito, decidimos que
                estava na altura de investir no que de melhor tinhamos e criar
                um sistema capaz de criar soluções de forma rápida e eficaz
                com a capacidade de se adaptar de forma ágil às necessidades
                de cada negócio. E foi assim, que nasceu a Canow. Com a Canow
                lançada, investimos ainda mais para que se afirma-se no
                mercado, com dois projetos distintos. O projeto Cognita que
                revolucionou a gestão e análise de dados em grande escala e o
                projeto Excello que evoluiu os Parques de Contentores.
              </STypographyText>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex", justifyContent: "center", }} >
            <STypographyYear marginTop={"20px"}>2025</STypographyYear>
        <Grid/>
        <Grid container sx={{ display: { xs: "flex" }, justifyContent: "center", }}>
          <Grid item xs={11} lg={10} sx={{}}>
            <STypographyTitle>Uma nova era</STypographyTitle>
          </Grid>
          <Grid container item xs={11} lg={10} sx={{ display: "flex", justifyContent: "right" }} >
            <STypographyText>
              Nesta epóca nasceu ainda a Oxi.geniu, um centro de
              formação certificado com o objetivo de fomentar o setor
              socioéconomico do país.
            </STypographyText>
          </Grid>
          <Grid container item xs={11} lg={10} sx={{ display: "flex", justifyContent: "right" }} >
            <Typography sx={{ color: "var(--green)", fontSize: { xs: "20px", lg: "26px" }, fontFamily: { xs: "Poppins Medium", lg: "Poppins Semibold" }, marginTop: { xs: "46px", lg: "57px" }, width: { xs: "100%", lg: "580px" }, }} >
              Temos como objetivo não reinventar a roda, mas torna-la ainda
              mais eficaz e já estamos a rolar para isso...
            </Typography>
          </Grid>
        </Grid>
        </Grid>
      </Container>
      </T2Container>

    </>
  );
}

export default Timeline;
