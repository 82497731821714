import { Grid } from '@mui/material'
import React from 'react';
import data from './data.json';
import {SContainer2, SPaper2, STypographyTitle2,STypographyText2} from '../../style.jsx';

function Box() {
  return (
    <SContainer2 maxWidth="lg" sx={{marginBottom:'64px'}}>
     <Grid container sx={{display:'flex', justifyContent:'space-between', gap:'33px'}}>
      {data.Data.map((item, index) => (
        index < 3 &&(
        <Grid item xs={12} sm={12} md={5} lg key={index}>
              <SPaper2 elevation={0}>
                <STypographyTitle2>{item.Title}</STypographyTitle2>
                <STypographyText2>{item.Text}</STypographyText2>
              </SPaper2>
            </Grid>
      )
      ))}
    </Grid>
    </SContainer2>
  )
}

export default Box