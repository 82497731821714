import { Box, Grid, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from "styled-components";

export const SBox = styled(Box)`
  background: var(--blue);
  border-radius: 5px;
  box-shadow: 0px 0px 6px #0000000f;
  position: absolute;
  top: 140px;
  right: 0;
  z-index: 2;
  @media (max-width: 600px) {
    position: relative;
    top: 0;
    right: 0;
    margin: auto;
  }
`;

export const SBox2 = styled(Box)`
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #0000000f;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AGrid = styled(Grid)`
  &&{
    padding: 0px 4px 0px 16px;

    height: 42px;
    width: 258px;

    cursor: pointer;
  }
  &.active{
    width: 294px;
  }
  & RemoveIcon {
    color: var(--green);
    display: none;
  }
  
`;
export const ARemoveIcon = styled(RemoveIcon)`
  &&{
    color: var(--green);
    display: none;
  }
  &.active{
    display: block;
  }
  
`;
export const AAddIcon = styled(AddIcon)`
  &&{
    color: var(--green);
    display: block;
  }
  &.active{
    display: none;
  }
  
`;

export const BGrid = styled(Grid)`
  &&{
    display: none;
  }
  &.active{
    display: block;
    padding: 0px 16px 16px 16px;
  }
`;

export const STypographyTitle = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 18px;
    font-family: Poppins Medium;
    color: var(--green);
  }
`;

export const STypographyTitle2 = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 16px;
    font-family: Poppins SemiBold;
    color: var(--blue);
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    font-family: Poppins Regular;
    color: var(--dark);
    width: 230px;
  }
`;
