import { Container, Typography } from "@mui/material";
import styled from "styled-components";
import Privacy from "../../../../assets/images/privaypolicy.png";

export const STypography = styled(Typography)`
  && {
    color: var(--white);
    font-size: 76px;
    font-weight: 400;
    font-family: Poppins Regular;
    width: 511px;
    @media (max-width: 600px) {
      width: 100%;
      font-size: 48px;
    }
  }
`;

export const SContainer = styled(Container)`
  background: url(${Privacy});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
`;
