import "./App.css";
import Footer from "./components/footer/Footer.jsx";
import Routes from "./routes";
import Cookies from "./components/cookieBtn/cookieBtn";

function App() {
  return (
    <>
      <Routes />
      <Cookies />
      <Footer />
    </>
  );
}

export default App;
