import React, { useEffect, useState } from "react";
import { SBox } from "./style.jsx";
import { IconButton, Tooltip } from "@mui/material";
import CookieIcon from "../../assets/icons/cookie.svg";
import Message from "../cookiemessage/message.jsx";

export default function CookieBtn() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("isFirstTime", "false");
  };

  useEffect(() => {
    const isFirstTime = localStorage.getItem("isFirstTime");
    if (!isFirstTime) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <SBox>
        <Tooltip title="Cookies">
          <IconButton onClick={handleClick}>
            <img
              src={CookieIcon}
              alt="Cookie Icon"
              style={{ filter: "drop-shadow(0px 0px 6px #06F2CE" }}
            />
          </IconButton>
        </Tooltip>
      </SBox>
      {open && <Message open={open} handleClose={handleClose} />}
    </>
  );
}
