import React, { useState } from "react";

import Video from "../../wrappervideo/Videobg.jsx";

import ContactFixed from "../../contact/components/maincontactbtn.jsx";
import { Box } from "@mui/material";

export default function Section1() {
  
  return (
    <>
      <Video />
      <Box
        sx={{
          position: "fixed",
          top: "140px",
          right: "0",
          borderRadius: " 4px ",
          background: "rgba(255,255,255,0.16)",
          zIndex: "5",
        }}
      >
        <ContactFixed />
      </Box>
    </>
  );
}
