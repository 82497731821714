import { Divider, Typography } from "@mui/material";
import styled from "styled-components";

export const STypography = styled(Typography)`
  && {
    font-size: 56px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--white);
    text-shadow: 0px 1px 6px var(--white-fourth);
    @media (max-width: 600px) {
      font-size: 26px;
    }
  }
`;

export const SDivider = styled(Divider)`
  && {
    max-width: 690px;
    height: 6px;
    background: var(--green-third);
    border-radius: 3px;
    box-shadow: 0px 3px 6px var(--green-third);
  }
`;

export const CustomImg = styled.img`
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
  height: 100%;
  width: 100%;

  @media (max-width: 1200px) { 
    display: none;
  }
`;

export const CustomImg2 = styled.img`
  position: relative;
  width: 100%;
  max-width: 500px;
  
  @media only screen and (max-width: 699.5px){
    padding: 0px 40px;
  }
`;

export const CustomImg3 = styled.img`
  position: relative;
  width: 100%;
  border-radius: 10px;
  max-width: 500px;

  border: 2px solid transparent; 
  
  &:hover {
    transition: 0.4s;
    border: 2px solid #06f4d0;
    box-shadow: 0px 0px 6px #06f4d0;
  }
`;

export const Title = styled(Typography)`
  && {
    font-size: 61px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: #fcfcfc;
    @media only screen and (max-width: 376.5px){
      font-size: 55px;
    }
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins Regular;
    color: #06f4d0;
    padding: 16px 0px;
  }
`;

export const Text = styled(Typography)`
  && {

    font-size: 20px;
    font-weight: normal;
    font-family: Poppins Regular;
    color: #fcfcfc;
  }
`;
