import {  Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const SContainer = styled(Container)`
  && {
    display: block;

    box-shadow: 0px 0px 1px 2px var(--green2);
    
    @media only screen and (max-width: 1099.5px){
      display: none;
    }
  }
`;

export const Sp = styled.p`
  && {
    font-weight: normal;
    font-family: Poppins Regular;
    font-size: 20px;
    color: white;

    margin: 0px 0px;
    padding: 0px;

    box-shadow: 0px 4px 1px -1px var(--green2);

    width: 0px;
    max-width: fit-content;
    text-wrap: nowrap;
    
    

    &:hover{
      transition: width .4s;
      width: 100%;
    }
  }
`

export const STypography = styled(Typography)`
  && {
    
  }
`;

export const IGrid = styled(Grid)`
  && {
    height: fit-content;
  }
`;

export const Ia = styled.a`
  && {
    text-decoration: none var(--green);
  }
  & p {
    color: var(--blue);
  }
`;