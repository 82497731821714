import { Grid, List } from "@mui/material";
import React, { useState } from "react";
import {
  SContainerInfo,
  STypographyTitle,
  STypographyText,
  STypographyList,
  SButton,
} from "../../style";
import dataInfo from "./data.json";
import ModalContact from "../../../../components/contact/components/ContactFormModal";

function Box() {
  const [data] = useState(dataInfo.Data);

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      {data.map((item, index) => (
        <SContainerInfo key={index} maxWidth="lg">
          <Grid container>
            <Grid item md={6} sm={12} xs={12}>
              <STypographyTitle>{item.Title}</STypographyTitle>
              <STypographyText>{item.Text}</STypographyText>
              <SButton
                onClick={handleOpen}
                disableRipple
                disableElevation
                variant="contained"
              >
                Contactar
              </SButton>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: { xs: "32px", lg: "0px" },
              }}
            >
              {Array.isArray(item.List) && item.List.length > 0 && (
                <List
                  sx={{
                    listStyleType: "disc",
                    "& .MuiListItem-root": {
                      display: "list-item",
                      pl: 0,
                      pr: 0,
                      ml: 3,
                    },
                  }}
                >
                  {item.List.map((listItem, listIndex) => (
                    <STypographyList key={listIndex}>
                      {listItem}
                    </STypographyList>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>
          {openModal && (
            <ModalContact openModal={openModal} handleClose={handleClose} />
          )}
        </SContainerInfo>
      ))}
    </>
  );
}

export default Box;
