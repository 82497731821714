import React,{useState} from "react";
import { Grid } from "@mui/material";
import {
  STypographyTitle,
  STypographyText,
  SButton,
  SPaper,
  SContainer2,
} from "../../style";
import ModalContact from "../../../../components/contact/components/ContactFormModal";

function Box() {

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <SContainer2 maxWidth="lg">
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", gap: "33px" }}
      >
        <Grid item xs>
          <SPaper>
            <STypographyTitle>Diagnóstico Empresarial</STypographyTitle>
            <STypographyText>
              Ganhe clareza da situação atual da sua empresa. Resultados
              positivos nem sempre significam uma boa rentabilidade ou
              desempenho. Devemos compreender o grau de maturidade do negócio e
              a lucratividade do mesmo. Os custos que podem ser reduzidos, os
              possíveis riscos adiante e as oportunidades que devemos
              aproveitar.
            </STypographyText>
            <SButton onClick={handleOpen} disableRipple disableElevation variant="contained">
              Contactar
            </SButton>
          </SPaper>
        </Grid>
        <Grid item xs>
          <SPaper>
            <STypographyTitle>Contablística & Financeira</STypographyTitle>
            <STypographyText>
              Aplicamos de forma criteriosa todas as regras contabilísticas e
              financeiras de forma a garantir o cumprimento das obrigações
              fiscais e contabilísticas. Análisamos e determinamos qual a melhor
              opção para as necessidades da sua empresa.
            </STypographyText>
            <SButton onClick={handleOpen} disableRipple disableElevation variant="contained">
              Contactar
            </SButton>
          </SPaper>
        </Grid>
      </Grid>
      {openModal && (
        <ModalContact openModal={openModal} handleClose={handleClose} />
          )}
    </SContainer2>
  );
}

export default Box;
