import React from "react";
import { STypography, SContainerVideo, TGrid } from "./style.jsx";
import { Grid } from "@mui/material";
import bgVideo from "../../assets/video/video_op.mp4";
import Header from "../../components/header/Header.jsx";
import BoxCognita from "../cognita/box.jsx";

function Video() {
  return (
    <SContainerVideo maxWidth={false}>
      <video autoPlay muted loop>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <Grid
        container
        item
        height="100%"
        sx={{
          position: { xs: "static", lg: "relative" },
          display: { xs: "flex", lg: "" },
        }}
      >
        <Header></Header>

        <TGrid container item xs={12} justifyContent={"center"} alignItems={"center"} >
          <STypography>We build solutions<br/>From start to finish</STypography>
        </TGrid>
        <BoxCognita />
      </Grid>
    </SContainerVideo>
  );
}

export default Video;
