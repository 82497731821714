import {
    Button,
    Checkbox,
    Container,
    FormLabel,
    Grid,
    IconButton,
    Input,
    MenuItem,
    Select,
    Typography,
  } from "@mui/material";
  import styled from "styled-components";
  
  export const SImg3 = styled.img`
    position: absolute;
    top: -35%;
    left: -8%;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  `;
  
  export const SImg4 = styled.img`
    position: absolute;
    bottom: -40%;
    right: -8%;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  `;
  
  export const SButtonContact = styled(Button)`
    && {
      width: 200px;
      height: 56px;
      border-radius: 5px;
      background: var(--green);
      border: 5px solid var(--green);
      box-shadow: 0px 3px 6px var(--green);
      text-transform: none;
      font-family: Poppins Medium;
      font-size: 20px;
      font-weight: 500;
      color: var(--light);
      margin-top: 33px;
      position: relative;
      &:hover {
        background: transparent;
        ${SImg3} {
          transform: scale(1);
          top: -48px;
          left: -70px;
          opacity: 1;
        }
        ${SImg4} {
          transform: scale(1);
          bottom: -58px;
          right: -70px;
          opacity: 1;
        }
      }
    }
  `;
  
  export const STypographyBtn = styled(Typography)`
    && {
      font-family: Poppins Regular;
      font-size: 20px;
      font-weight: 400;
      color: var(--green);
      text-transform: none;
      display: none;
    }
  `;
  
  export const SButtonContactFixed = styled(Button)`
    width: 80px;
    height: 80px;
    border-radius: 8px;
    position: fixed;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      width: 212px;
      border: 2px solid var(--green);
      content: "Fale Conosco";
      transition: width 0.3s ease-in-out;
      ${STypographyBtn} {
        display: block;
        transition: 0.3s ease-in-out;
      }
    }
  `;

  export const SButton = styled(IconButton)`
  && {
    position: absolute;
    top: 35px;
    right: 40px;

    height: 32px;
    width: 32px;
    padding: 0;

    z-index: 1;

    @media only screen and (max-width: 865px){
      top: 20px;
      right: 20px;
    }
  }
`;
  
  export const SGrid = styled(Grid)`
    && {
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      padding: 64px;
      position: relative;
      
      @media (max-width: 865px) {
        padding: 16px;
      }
    }
  `;
  
  export const SText = styled(Typography)`
    && {
      font-family: "Poppins" ;
      font-size: 26px;
      width: 100%;
      font-weight: 500;
      color: var(--light);
      text-shadow: 0px 3px 6px var(--green);
      text-align: center;
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
  `;
  
  export const SImg = styled.img`
    position: absolute;
    top: -3%;
    left: -0.5%;
    width: 50%;
  `;
  
  export const SImg2 = styled.img`
    position: absolute;
    bottom: -8%;
    right: -2%;
    width: 50%;
  `;
  
  export const SContainerContact = styled(Container)`
    && {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      padding: 32px;
      backdrop-filter: blur(30px);
    }
  `;
  
  export const GridContainer1 = styled(Grid)`
    && {
      padding: 32px;
      border-radius: 8px;
      box-shadow: 0px 0px 6px var(--green-third);
      background: var(--light);
      display: flex;
      flex-direction: column;
      gap: 32px;
      @media (max-width: 1200px) {
        max-width: 48%;
      }
      @media (max-width: 865px) {
        display: none;
      }
    }
  `;
  
  export const GridContainer2 = styled(Grid)`
    && {
      padding: 32px;
      border-radius: 8px;
      box-shadow: 0px 0px 6px var(--green-third);
      background: var(--light);
      display: flex;
      gap: 8px;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 1200px) {
        max-width: 48%;
      }
      @media (max-width: 865px) {
        max-width: 100%;
      }
    }
  `;

  export const PGrid = styled(Grid)`
    &&{
      margin-bottom: 20px;
      @media only screen and (min-width: 1199.5px){
        margin: 20px 0px;
      }
    }
  `;
  
  export const STypographyContactTitle = styled(Typography)`
    && {
      font-size: 30px;
      font-weight: 600;
      font-family: Poppins Medium;
      color: var(--blue);
    }
  `;
  
  export const STypographyContactSubText = styled(Typography)`
    && {
      font-size: 23px;
      font-weight: 300;
      font-family: Poppins Regular;
      color: var(--darck-gray);
    }
  `;
  
  export const STypographyContactText = styled(Typography)`
    && {
      font-size: 17px;
      font-weight: 400;
      font-family: Poppins Regular;
      color: var(--darck-gray);
    }
  `;
  
  export const SLabel = styled(FormLabel)`
    && {
      font-size: 20px;
      font-weight: normal;
      font-family: Poppins Regular;
      color: var(--blue);
    }
  `;
  
  export const SInput = styled(Input)`
    && {
      width: 100%;
      height: 36px;
      padding-left: 12px;
      margin: 0;
      margin-bottom: 2px;
      border-radius: 5px;
      background: rgba(223, 222, 222, 0.33);
      text-decoration: none;
      font-family: Poppins Regular;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }

      &:hover{
        border: 2px solid var(--blue);
      }
    }
  `;

  export const SInputSelect = styled(Select)`
    && {
      width: 100%;
      height: 36px;
      margin: 0;
      border-radius: 5px;
      background: rgba(223, 222, 222, 0.33);
      text-decoration: none;
      color: var(--blue);
      font-family: Poppins Regular;
      
    }
  `;
  
  export const SSelect = styled(Select)`
  && {
      width: 100%;
      height: 36px;
      border-radius: 5px;
      background: rgba(223, 222, 222, 0.33);
      font-size: 20px;
      font-weight: 300;
      font-family: "Poppins";
      color: var(--blue);
      &:hover{
        border: 1px solid var(--blue);
      }
    }
  `;

  export const SMenuItem = styled(MenuItem)`
    &&{
     font-family: Poppins Regular;
    }
  `;

  export const SCheckbox = styled(Checkbox)`
    && {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      color: var(--light);
      border: 1px solid var(--blue);
      margin-right: 10px;
    }
  `;
  
  export const STypographyCheck = styled(Typography)`
    && {
      font-size: 14px;
      font-weight: 400;
      font-family: Poppins Regular;
      color: var(--dark);
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
  `;
  
  export const SButtonSend = styled(Button)`
    && {
      border-radius: 8px;
      background: var(--blue);
      width: 147px;
      height: 36px;
      color: var(--light);
      font-size: 20px;
      font-family: Poppins Regular;
      font-weight: 400;
      &:hover {
        background: var(--green);
        color: var(--blue);
      }
      @media only screen and (max-width: 1065.5px) and (max-width: 864.5px) {
        width: 100%;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  `;
  
  export const SContainerForm = styled(Container)`
    && {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      padding: 32px;
      backdrop-filter: blur(30px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 865px) {
        padding: 16px;
      }

      
    }
  `;
  