import React from "react";
import { Container, Grid } from "@mui/material";
import { AGrid, SContainer, STypography, STypographyDesc2 } from "../../style";



function Rights() {
  return (
    <SContainer maxWidth={false} disableGutters >
      
      <Container maxWidth="lg" >
        <Grid
          container
          justifyContent={"flex-start"}
          sx={{
            display: "flex",
          }}
        >
          <AGrid className="ga1" item xs={12} >
            <STypography>Legal</STypography>
          </AGrid>
          <AGrid className="ga2" item xs={12} sx={{ textAlign: { lg: "start" } }} >
            <a href="cookies" style={{textDecoration:'none'}} ><STypographyDesc2>Política de Cookies</STypographyDesc2></a>
          </AGrid>
          <AGrid className="ga3" item xs={12} sx={{ textAlign: { lg: "center" } }} >
            <a href="privacy" style={{textDecoration:'none'}} ><STypographyDesc2>Política de Privacidade</STypographyDesc2></a>
          </AGrid>
          <AGrid className="ga4" item xs={12} sx={{ textAlign: { lg: "center" } }} >
            <a href="condicoes" style={{textDecoration:'none'}} ><STypographyDesc2>Termos e Condições</STypographyDesc2></a>
          </AGrid>
          <AGrid className="ga5" item xs={12} sx={{ textAlign: { lg: "center" } }} >
            <STypographyDesc2>Livro de Reclamações</STypographyDesc2>
          </AGrid>
          <AGrid className="ga6" item xs={12} sx={{ textAlign: { lg: "end" } }} >
            <STypographyDesc2>
              ©2024 JCanão. All rights reserved.
            </STypographyDesc2>
          </AGrid>
        </Grid>
      </Container>
    </SContainer>
  );
}

export default Rights;
