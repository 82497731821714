import React, { useState } from "react";

import { Container, Grid } from "@mui/material";
import ContactText from "../../contact/contactText.jsx";
import ContactBtn from "../../contact/components/contactbtn.jsx";
import { BGrid } from "./style.js";

export default function Section3() {
  
  return (
    <>
    
    <BGrid container item xs={12}>
      <Container
        maxWidth="xl"
        sx={{
          height: "auto",
          marginTop: { xs: "64px", lg: "" },
        }}
      >
        <Grid
          item
          align="center"
          sx={{ marginTop: { xs: "64px", lg: "200px" }, marginBottom: "140px"}}
        >
          <ContactText />
        </Grid>
        <Grid
          item
          align="center"
          sx={{ marginTop: { xs: "32px", lg: "64px" }, marginBottom: { xs: "50px", lg: "200px" } }}
        >
          <ContactBtn />
        </Grid>
      </Container>
    </BGrid>
    </>
  );
}
