import { Button } from "@mui/base";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const SBox = styled(Box)`
  padding: 64px;
  border-radius: 10px;
  border: 2px solid var(--green);
  box-shadow: 0px 0px 6px var(--green);
  background-color: var(--blue);
  margin-top: 64px;
  @media (max-width: 600px) {
    margin-top: 100px;
    padding: 32px;
  }
`;

export const STypographyTitle = styled(Typography)`
  && {
    font-size: 36px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--green);
    text-align: left;
    margin-bottom: 32px;
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
    text-align: left;
    margin-bottom: 16px;
  }
`;

export const SButton3 = styled(Button)`
  && {
    border: 3px solid var(--green-third);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(30px);
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    margin-top: 112px;
    margin-bottom: 33px;
    cursor: pointer;
    &:hover {
      background: var(--white);
    }
  }
`;
