import { Button, Container } from "@mui/material";
import styled from "styled-components";

export const BContainer = styled(Container)`
  && {
    
  }
`;

export const SButton3 = styled(Button)`
  && {
    border: 3px solid var(--green-third);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.16);
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    &:hover {
      background: var(--white);
    },
    position: absolute;
    margin-bottom: 30px;
    z-index: 2;
  }
`;
