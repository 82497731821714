import { Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const SContainerVideo = styled(Container)`
  && {
    z-index: 10;

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flexDirection: "column",
    overflow: hidden;
    padding: 0;
    margin: 0;
    position: relative;
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    @media only screen and (max-width: 1277.8px){
      min-height: 896.8px;
    }
  }
`;

export const TGrid = styled(Grid)`
  && {
    margin: 190px 0px; 
    padding: 0px 24px;

    @media only screen and (max-width: 1277.8px){
      margin: 0px;
    }
    @media only screen and (max-width: 600.8px){
      margin-top: 100px;
    }
  }
`;

export const STypography = styled(Typography)`
  && {
    font-size: 130px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
    text-shadow: 3px 3px 6px var(--gray);
    width: 1300px;
    text-align: center;

    @media only screen and (max-width: 1277.8px){
      font-size: 64px;
    }
  }
`;
