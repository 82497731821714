import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";

export const SBox = styled(Box)`
  background: var(--white);
  border-radius: 8px;
  padding: 30px;
  max-width: 812px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  position: absolute;
  @media only screen and (min-width: 900px){
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media only screen and (max-width: 900px){
    margin: 0px 20px;
    min-width: 0px;

    top: 50%;
    transform: translateY(-50%);
  }
`;

export const STypography = styled(Typography)`
  && {
    font-size: 24px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--blue);
    text-align: left;
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--dark);
    text-align: left;
    @media only screen and (max-width: 500px){
      font-size: 16px;
    }
  }
`;

export const Sspan = styled.span`
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
`;

export const SButtonAccept = styled(Button)`
  && {
    padding: 10px 26px;
    background: var(--blue);
    border-radius: 8px;
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins Medium;
    text-transform: none;
    &:hover {
      background: var(--blue);
    }
  }
`;

export const SButtonDeny = styled(Button)`
  && {
    padding: 10px 26px;
    background: var(--dark);
    border-radius: 8px;
    color: var(--white);
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins Medium;
    text-transform: none;
    &:hover {
      background: var(--dark);
    }
  }
`;
