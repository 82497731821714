import { Box, Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const HGrid = styled(Grid)`
  && {
    height: 114px;
    max-width: fit-content;
    @media only screen and (min-width: 769px) and (max-width: 791.5px){
      max-width: 100%;
    }
  }
`;

export const TContainer = styled(Container)`
  && {
    @media only screen and (max-width: 1199.5px){
      display: none;
    }
  }
`;

export const T2Container = styled(Container)`
  && {
    display: none;
    @media only screen and (max-width: 1199.5px){
      display: block;
    }
  }
`;

export const STypography = styled(Typography)`
  && {
    width: 100%;
    font-family: Poppins Medium;
    font-size: 76px;
    font-weight: 500;
    color: var(--white);
    @media (max-width: 768px) {
      font-size: 36px;

    }
  }
`;

export const STypographyYear = styled(Typography)`
  && {
    font-family: Poppins SemiBold;
    font-weight: 600;
    font-size: 144px;
    color: var(--white);
    text-shadow: 0px 3px 6px var(--green-third);
    @media (max-width: 768px) {
      font-size: 56px;
      align-self: flex-start;
    }
  }
`;

export const STypographyTitle = styled(Typography)`
  && {
    font-family: Poppins SemiBold;
    font-weight: 600;
    font-size: 76px;
    color: var(--blue);
    text-shadow: 0px 0px 8px var(--green);
    opacity: 1;
    padding-bottom: 40px;
    padding-top: 40px;
    -webkit-text-stroke: 1px var(--green);
    @media (max-width: 768px) {
      font-size: 56px;
      padding-bottom: 32px;
      line-height: 1;
    }
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-weight: 400;
    font-size: 18px;
    color: var(--white);
    @media (max-width: 860px) {
      font-size: 16px;
      text-align: left;
      font-family: Poppins Light;
    }
  }
`;

export const SBox = styled(Box)`
  border: 5px solid var(--green-third);
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0px 1px 6px var(--green-third);
  height: max-content;
  position: relative;
  max-width: 450px;
  float: right;
  
`;

export const Line1 = styled.div`
  &&{
    position: absolute;
    width: 2px;
    height: 1250px;
    background-color: white;
    left: -24.5%;
    top: -700px;
    &::before{
      content: "";
      position: absolute;
      width: 64px;
      height: 64px;
      background-color: var(--green-third);
      border-radius: 50%;
      left:-32px;
      top: -32px;
    }
    &::after{
      content: "";
      position: absolute;
      width: 64px;
      height: 64px;
      background-color: var(--green-third);
      border-radius: 50%;
      left:-32px;
      top: 720px;
    }
  }
  @media (max-width: 1500px) {
    display: none;
  }
`;

export const Line2 = styled.div`
  &&{
    position: absolute;
    width: 405px;
    height: 2px;
    background-color: white;
    left: -58.5%;
    top: 355px;
    @media (max-width: 1540px) {
      width: 400px;
    }
    @media (max-width: 1500px) {
      display: none;
    }
  }
`;

export const Line3 = styled.div`
  &&{
    position: absolute;
    width: 2px;
    height: 722px;
    background-color: white;
    left: -20%;
    top: -33.5%;
    &::before{
      content: "";
      position: absolute;
      width: 64px;
      height: 64px;
      background-color: var(--green-third);
      border-radius: 50%;
      left:-32px;
      top: 210px;
    }
    @media (max-width: 1500px) {
      display: none;
    }
  }
`;

export const Line4 = styled.div`
  &&{
    position: absolute;
    width: 350px;
    height: 2px;
    background-color: white;
    left: -25%;
    top: -30%;
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1540px) {
      width: 225px;
    }
  }
`;

export const Line5 = styled.div`
  &&{
    position: absolute;
    width: 2px;
    height: 500px;
    background-color: white;
    left: -25%;
    top: -30%;
    @media (max-width: 1500px) {
      display: none;
    }
  }
`;

export const Line6 = styled.div`
  &&{
    position: absolute;
    width: 580px;
    height: 2px;
    background-color: white;
    left: 32%;
    top: -33%;
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1540px) {
      width: 460px;
      left: 31%;
      top: -32%;
    }
  }
`;
export const Line7 = styled.div`
  &&{
    position: absolute;
    background-color: white;
    width: 2px;
    height: 580px;
    left: 118%;
    top: -33%;
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1540px) {
      height: 600px;
      top: -32%;
      left: 117%;
    }
  }
`;

export const Line8 = styled.div`
  &&{
    position: absolute;
    background-color: white;
    height: 2px;
    width:120px;
    left: 93%;
    top: 48%;
    &::before{
      content: "";
      position: absolute;
      width: 64px;
      height: 64px;
      background-color: var(--green-third);
      border-radius: 50%;
      left:-32px;
      top: -32px;
    }
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1540px) {
      width: 50px;
      left: 104%;
      top: 44%;
    }
  }
`;