import React, { useState } from "react";

import {
  GridContainer1,
  GridContainer2,
  STypographyContactTitle,
  STypographyContactSubText,
  STypographyContactText,
  SInput,
  SLabel,
  SCheckbox,
  STypographyCheck,
  SButtonSend,
  SContainerForm,
  SInputSelect,
  SMenuItem,
  SButton,
  PGrid,
} from "./style.js";

import { Grid, CircularProgress, Modal } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import Close from "@mui/icons-material/Close.js";

export default function ContactForm({ openModal, handleClose }) {

  const [loading, setLoading] = useState(false);
  const [user_name, setUser_name] = useState("");
  const [user_empresa, setUser_empresa] = useState("");
  const [user_cargo, setUser_cargo] = useState("");
  const [user_mail, setUser_mail] = useState("");
  const [user_phone, setUser_phone] = useState("");
  const [user_produto, setUser_produto] = useState(null);
  const [Ustupid, setUstupid] = useState("");
  const [termos, setTermos] = useState(false);

  const sendEmail = (e) => {
    if (Ustupid.length === 0) {
    e.preventDefault();

    const data = {
      user_name,
      user_empresa,
      user_cargo,
      user_mail,
      user_phone,
      user_produto
    };

    setLoading(true);

    try {
      if (!user_name || !user_mail || !termos) {
        toast.error("Preencha os campos obrigatórios!");
        setLoading(false);
        return;
      }
      emailjs
        .send("service_5if4bwb", "template_hgdff1n", data, "MceY75AF-NlVUPOtK")
        .then((result) => {
          // handleOpenMessage();
          setLoading(false);
          toast.success("Email enviado");
        });
    } catch (error) {
      toast.error("Erro ao enviar o email!");
      setLoading(false);
    }
    
    }
  };

  const [produto, setproduto] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setUser_produto(event.target.value);
    setproduto(event.target.value);
  };

  return (
    <Modal open={openModal} onClose={handleClose} id="modalContact">
      <SContainerForm maxWidth="lg">
        <Grid container justifyContent={"center"} sx={{ display: "flex", gap: "32px" }}>
          <GridContainer1 item xs={12} lg>
            <STypographyContactTitle>
              Abra uma porta para o futuro
            </STypographyContactTitle>
            <STypographyContactSubText>
              Saiba como pode melhorar o seu negócio com as nossas ferramentas
              de fácil integração, adaptáveis ao detalhe de cada negócio.
            </STypographyContactSubText>
            <STypographyContactText>
              Deixe-nos o seu contacto para que lhe enviemos mais informação ou
              para marcar uma apresentação do produto sem qualquer compromisso.
            </STypographyContactText>
          </GridContainer1>

          <GridContainer2 item xs={12} lg >
          <SButton onClick={handleClose}><Close/></SButton>
            <Grid item marginTop={"0px"}>
              <SLabel>Nome</SLabel>
              <SInput
                required
                disableUnderline={true}
                onChange={(e) => setUser_name(e.target.value)}
              />
            </Grid>

            <Grid container gap={3} rowGap={0}  marginTop={"0px"}>
              <Grid item xs={12} md marginTop={"0px"}>
                <SLabel>Empresa</SLabel>
                <SInput
                  disableUnderline={true}
                  onChange={(e) => setUser_empresa(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md marginTop={"0px"}>
                <SLabel>Cargo</SLabel>
                <SInput
                  disableUnderline={true}
                  onChange={(e) => setUser_cargo(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container gap={3} rowGap={0} marginTop={"0px"}>
              <Grid item xs={12} md>
                <SLabel>Email*</SLabel>
                <SInput
                  required
                  disableUnderline={true}
                  onChange={(e) => setUser_mail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md>
                <SLabel>Telemóvel</SLabel>
                <SInput
                  name="user_phone"
                  type={"number"}
                  disableUnderline={true}
                  onChange={(e) => setUser_phone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{maxHeight:0, maxWidth:0, overflow: "hidden"}}>
                  <input 
                    disableUnderline={true}
                    type="text" id="Ustupid" name="RUstupid"
                    placeholder={"yes"}
                    onChange={(e) => setUstupid(e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>
            <PGrid container alignItems={"center"} marginTop={"0px"} sx={{ display: "flex", gap: "10px" }}>
                <Grid item xs={12} lg={4}>
                <SLabel>Pretendo obter</SLabel>
                </Grid>
                <Grid item xs={12} lg >
                <SInputSelect 
                    name="user_produto"
                    defaultValue="Demo de um produto"
                    onChange={handleChange}
                >
                    <SMenuItem selected value={"Demo de um produto"}>Demo de um produto</SMenuItem>
                    <SMenuItem value={"Mais informação"}>Mais informação</SMenuItem>
                    <SMenuItem value={"Outros Planos"}>Outros Planos</SMenuItem>
                    <SMenuItem value={"Entrada em contacto"}>Entrada em contacto</SMenuItem>
                </SInputSelect>
                </Grid>
            </PGrid>

            <Grid container justifyContent={{xs:"space-between", sm:""}}>
              <Grid
                item
                xs={7.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SCheckbox
                  type="checkbox"
                  onChange={(e) => {
                    setTermos(e.target.checked);
                  }}
                />
                <STypographyCheck>
                  Li e aceito os{" "}
                  <a
                    href="/TermosCondições"
                    target="_blank"
                    style={{
                      color: "var(--blue)",
                      textDecoration: "underline",
                    }}
                  >
                    termos de Privacidade
                  </a>
                </STypographyCheck>
              </Grid>
              <Grid item xs={4.5} align="end">
                <SButtonSend type="submit" onClick={sendEmail}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "var(--white)",
                      }}
                    />
                  ) : (
                    "Enviar"
                  )}
                </SButtonSend>
              </Grid>
            </Grid>
          </GridContainer2>
        </Grid>
      </SContainerForm>
    </Modal>
  );
}
