import styled from "styled-components";
import Button from "@mui/material/Button";

export const SButton = styled(Button)`
  && {
    height: 100%;
    max-width: 181px;
    width: 100%;
    color: white;
    font-size: 29px;
    text-transform: none;
    font-family: Poppins Regular;
    border-radius: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      background: rgba(255, 255, 255, 0.14) 0% 0% no-repeat padding-box;
      border-bottom: 1px solid var(--green);
    }
  }
`;
