import { Box, CircularProgress, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import data from "./data";
  /*
  "Redes Sociais": [
    { "icon": "linkedin","iconPath":"../../assets/images/linkedin.svg", "link": "https://pt.linkedin.com/company/jcanao"},
    { "icon": "facebook","link": "https://www.facebook.com/jcanao.pt" }
  ],
  */
import { STypographyTitle, STypographyDesc, GridContainer1, GridContainer1_2, SButtonMedia, BoxContactoText1, BoxContactoText2, SLabel, SInput, SButtonSend, SCheckbox, STypographyCheck, GridContainer2, COGrid, OGrid, GridContainer1deca2 } from "./style";
import Rights from "./components/rights/Rights.jsx";
import { Link } from "react-router-dom";
import Facebook from "../../assets/images/facebook.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import Certificacoes from "../../assets/images/certifs.svg";
import JcanaoWhite from "../../assets/images/jcanao_logo.svg";
import { toast } from "react-toastify";

import emailjs from "@emailjs/browser";

import { ReactComponent as What } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as Mes } from "../../assets/icons/bxl-messenger.svg";

const iconMapping = {
  "facebook":Facebook,
  "linkedin":Linkedin,
}

const imageMapping = {
  "certificacoes":Certificacoes,
  "JCANAO":JcanaoWhite,
}

function Footer() {

  
  const [loading, setLoading] = useState(false);
  const [user_name, setUser_name] = useState("");
  const [user_empresa, setUser_empresa] = useState("");
  const [user_cargo, setUser_cargo] = useState("");
  const [user_mail, setUser_mail] = useState("");
  const [user_phone, setUser_phone] = useState("");
  const [Ustupid, setUstupid] = useState("");
  const [termos, setTermos] = useState(false);

  const sendEmail = (e) => {
    if (Ustupid.length === 0) {
    e.preventDefault();

    const data = {
      user_name,
      user_empresa,
      user_cargo,
      user_mail,
      user_phone
    };

    setLoading(true);
    
    try {
      if (!user_name || !user_mail || !termos) {
        toast.error("Preencha os campos obrigatórios!");
        setLoading(false);
        return;
      }
      emailjs
        .send("service_5if4bwb", "template_hgdff1n", data, "MceY75AF-NlVUPOtK")
        .then((result) => {
          // handleOpenMessage();
          setLoading(false);
        });
    } catch (error) {
      toast.error("Erro ao enviar o email!");
      setLoading(false);
    }
    }
  };

  return (
    <>
      <Container maxWidth={false} disableGutters sx={{ background: "var(--blue)", padding:'158px 0px 0px 0px'}}>
        <Container maxWidth="lg">
        <Grid container item xs={12} display={'flex'} justifyContent={'space-between'}>
        <GridContainer1deca2 container item xs={12} justifyContent={"space-between"}>
            <SButtonMedia variant="contained" onClick={() => window.location.href = 'https://m.me/1008971482454570'} fullWidth endIcon={<Mes />}>
              Messenger
            </SButtonMedia>
            <SButtonMedia variant="contained" onClick={() => window.location.href = 'https://wa.me/351924453260?text=Hello%2C%20I%20have%20a%20question%20about%20your%20services.'} fullWidth endIcon={<What />}>
              Whatsapp
            </SButtonMedia>
          </GridContainer1deca2>
        <Grid container item xs={12} md={8.2} display={'flex'} justifyContent={'space-between'}>  
        <GridContainer1 item xs={12} >
          <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center"}}>
            <GridContainer1_2 item xs md={5}>
                <SButtonMedia className={"ColorIcon"} variant="contained" onClick={() => window.location.href = 'https://m.me/1008971482454570'} fullWidth endIcon={<Mes />}>
                  Messenger
                </SButtonMedia>
                <SButtonMedia className={"ColorIcon"} variant="contained" onClick={() => window.location.href = 'https://wa.me/351924453260?text=Hello%2C%20I%20have%20a%20question%20about%20your%20services.'} fullWidth  endIcon={<What />}>
                  Whatsapp
                </SButtonMedia>
                <BoxContactoText1 style={{ color: "#919191" }}>+351 <span style={{ color: "#1F69FF" }}>924 453 260</span></BoxContactoText1>
                <BoxContactoText1 style={{ color: "#919191" }}>+351 <span style={{ color: "#1F69FF" }}>258 800 600</span></BoxContactoText1>
                <BoxContactoText2>jcanao@jcanao.pt</BoxContactoText2>
            </GridContainer1_2>

            <Grid container item xs={12} md gap={"21px"} rowGap={"8px"} justifyContent={"space-between"}> 
              <Grid item xs={12}>
                <SLabel>Nome*</SLabel>
                <SInput
                  required
                  disableUnderline={true}
                  onChange={(e) => setUser_name(e.target.value)}
                />
              </Grid>
              <Grid container item xs={12} gap={"21px"} justifyContent={"space-between"}>
              <Grid item xs={12} md>
                <SLabel>Empresa</SLabel>
                <SInput
                  disableUnderline={true}
                  onChange={(e) => setUser_empresa(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md>
                <SLabel>Cargo</SLabel>
                <SInput
                  disableUnderline={true}
                  onChange={(e) => setUser_cargo(e.target.value)}
                />
              </Grid>
              </Grid>
              <Grid item xs={12} md>
                <SLabel>Email*</SLabel>
                <SInput
                  required
                  disableUnderline={true}
                  onChange={(e) => setUser_mail(e.target.value)}
                /> 
              </Grid>
              <Grid item xs={12} md={4.7}>
                <SLabel>Telemóvel</SLabel>
                <SInput
                  name="user_phone"
                  type={"number"}
                  disableUnderline={true}
                  onChange={(e) => setUser_phone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{maxHeight:0, maxWidth:0, overflow: "hidden"}}>
                  <input 
                    disableUnderline={true}
                    type="text" id="Ustupid" name="RUstupid"
                    placeholder={"yes"}
                    onChange={(e) => setUstupid(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid container item sx={{ display: "flex", gap: "21px", marginTop: "24px"}}>
                <Grid item xs={3}>
                  <SButtonSend type="submit" onClick={sendEmail}>
                    {loading ? (
                      <CircularProgress size={4} sx={{ color: "var(--white)", }} />
                    ) : (
                      "Enviar"
                    )}
                  </SButtonSend>
                </Grid>
                <Grid item xs sx={{ display: "flex", alignItems: "center" }} >
                  <SCheckbox type="checkbox" onChange={(e) => { setTermos(e.target.checked); }} />
                  <STypographyCheck>
                    Li e aceito os{" "}
                    <a
                      href="/Privacidade"
                      style={{
                        color: "var(--blue)",
                        textDecoration: "underline",
                      }}
                    >
                      termos de Privacidade
                    </a>
                  </STypographyCheck>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
          </GridContainer1>


            {Object.entries(data).slice(0,3).map(([title, items]) => (
              <OGrid
                item
                xs={12}
                key={title}
                style={{ paddingBottom: "12px"}}
                className={title === "Redes Sociais"? "mand" : null}
              >
                <STypographyTitle>{title}</STypographyTitle>
                {Array.isArray(items)
                  ? items.map((item) => (
                      <Link to={item.link} key={item.text} style={{textDecoration:'none'}} target="_blank">
                        {item.icon === "facebook" || item.icon === "linkedin"?(
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width:'fit-content',
                              gap:'16px'
                            }}
                          >
                            <img
                            
                              src={iconMapping[item.icon]}
                              alt={item.icon}
                              style={{display: "flex", padding:'0px', width:'max-content', height:'max-content' }}
                            />
                            <STypographyDesc key={item}>{item.text}</STypographyDesc>
                          </div>
                        ) : item.image === "certificacoes" ? (
                          <Box
                            key={item}
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              flexDirection: "column",
                              alignItems: "start",
                              cursor:'default'
                            }}
                          >
                            <img
                              src={imageMapping[item.image]}
                              style={{maxWidth:'100%', height:'auto'}}
                              alt=""
                            />
                            <STypographyDesc
                              key={item}
                              style={{ fontSize: "9px", marginTop: "5px" }}
                            >
                              {item.text}
                            </STypographyDesc>
                          </Box>
                        ) : (
                          <STypographyDesc key={item} fontWeight="bold">
                            {item.text === "Googlemaps" ? <strong>{item.text}</strong> : item.text}
                          </STypographyDesc>
                        )}
                      </Link>
                    ))
                  : ""}
              </OGrid>
              
            ))} 
          </Grid>
          
          <GridContainer2 container item xs={12} md sx={{ display: "flex" }}>
          {Object.entries(data).slice(3,6).map(([title, items]) => (
              <COGrid
                item
                xs={12}
                key={title}
                style={{maxHeight: "fit-content"}}
              >
                <STypographyTitle>{title}</STypographyTitle>
                {items.map((item) => (
                      <Link to={item.link} key={item.text} style={{textDecoration:'none'}} target="_blank">
                        { item.image === "JCANAO" ? (
                          <Box
                          style={{overflow: "hidden"}}
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",
                              cursor:'default'
                            }}
                          >
                            <img
                              src={imageMapping[item.image]}
                              style={{maxWidth:'100%', height:'auto', marginBottom: '16px'}}
                              alt=""
                            />
                          </Box>
                        ) : (
                          <STypographyDesc key={item} fontWeight="bold">
                            {item.text}
                          </STypographyDesc>
                        )}
                      </Link>
                    ))
                  }
              </COGrid>
              
            ))} 
          </GridContainer2>
          </Grid>
        </Container>
      </Container>
      <Rights />
    </>
  );
}

export default Footer;
