import { Box } from "@mui/material";
import styled from "styled-components";

export const SBox = styled(Box)`
  && {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 1px;
    box-shadow: 0px 0px 6px #0000000f;
    position: fixed;
    bottom: 0;  
    left: 0;
    z-index: 5;
    @media (max-width: 678px) {
      display:none;
    }
  }
`;