import React from "react";
import {
  CustomImg,
  Title,
  SubTitle,
  Text,
  CustomImg2,
  CustomImg3,
} from "./style.jsx";
import Oxygeniu from "../../assets/images/Oxygeniu.png";
import Alphaleme from "../../assets/images/Alphaleme.png";
import CanowImg from "../../assets/images/Canow.svg";
import { Container, Grid } from "@mui/material";
import BG3 from "../../assets/images/bg3.png";
import Bottom from "../../components/clientes/Clientes.jsx";
import Timeline from "../../components/timeline/Timeline.jsx";
import Header from "../../components/header/Header.jsx";
import can from "../../assets/images/canow_phrase.png";
function aboutUs() {
  return (
    <>
      <Header />
      <CustomImg src={BG3}></CustomImg>
      <Container> 
      <Grid container item xs={12} sx={{ marginTop:{ xs: "0px", lg:"10vh" } }}>
        <Grid
          container
          item
          xs={12}
          lg={7.5}
          sx={{
            flexDirection: "column",
            alignItems: { xs: "center", lg: "start" },
          }}
        >
          <Grid container item xs={12} lg={10} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sx={{ padding: "164px 0px"}}>
              <Title sx={{ marginTop:{xs: "80px", md: "150px", lg:"0px"}}}>Sobre Nós</Title>

              <SubTitle>Bem-vindo à J.Canão</SubTitle>
              <Text>
                Desde 1987, desempenhamos um papel fundamental na otimização dos
                processos dos nossos clientes, centrando-nos em três pilares
                essenciais: Administração Central e Local, diversas instituições
                Públicas e Privadas, e na Indústria e serviços. Com um vasto
                know-how que permeia toda a indústria Portuária e Logística,
                desenvolvemos soluções práticas que superam desafios reais.
              </Text>
            </Grid>
            <Grid item xs={12} sx={{ padding: "50px 0px 50px 0px" }}>
              <SubTitle>A Essência da J.Canão</SubTitle>
              <Text>
                Na nossa essência encontra-se o compromisso intransigente com a
                excelência. O nosso objetivo é assegurar que os nossos clientes
                alcancem os mais elevados padrões de qualidade,
                segurança, desempenho e fiabilidade.
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={4.5}>
          <Grid
            container
            item
            rowSpan="2"
            sx={{
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                container
                item
                xs={12}
                lg={12}
                sx={{ justifyContent: { xs: "center" } }}
              >
                <CustomImg2 src={CanowImg}></CustomImg2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      

      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Grid
              xs={12}
              lg={6}
              container
              item
              sx={{
                padding: "100px 0px",
                justifyContent: { xs: "center", lg: "start" },
              }}
            >
              <Grid item xs={12}>
                <SubTitle>Parceria para o Futuro Digital</SubTitle>
                <Text>
                  A nossa incessante busca pela excelência estende-se para além
                  do domínio tecnológico. Procuramos parceiros especializados
                  para garantir soluções benéficas aos nossos clientes. Mantemos
                  uma parceria duradoura com a <b>Alphaleme</b>, com ênfase na
                  consultoria em áreas como cibersegurança e RGPD.
                </Text>
              </Grid>
            </Grid>
            
            <Grid
              container
              item
              xs={12}
              lg={6}
              sx={{
                alignItems: "center",
                padding: { xs: "0px", lg: "32px" },
                justifyContent: { xs: "center", lg: "start" },
              }}
            >
              <a href="https://alphaleme.pt/">
              <CustomImg3 src={Alphaleme}></CustomImg3>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item sx={{}}>
            <Grid container item sx={{}}>
              <Grid
                xs={12}
                lg={6}
                container
                item
                sx={{
                  margin: "50px 0px 70px 0px",
                  justifyContent: { xs: "center", lg: "start" },
                }}
              >
                <Grid item xs={12}>
                  <SubTitle>Centro de Formação</SubTitle>
                  <Text>
                    No âmbito da procura de mais conhecimento, inauguramos em
                    2013 o <b>Oxygeniu</b>, um centro de formação que já
                    certificou milhares de profissionais. Com o nosso
                    compromisso em promover a educação contínua, proporcionamos
                    um ambiente propício ao desenvolvimento de competências
                    essenciais.
                  </Text>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={6}
                sx={{
                  alignItems: "center",
                  padding: { xs: "0px", lg: "32px" },
                  justifyContent: { xs: "center", lg: "start" },
                }}
              >
                <a href="https://oxygeniu.com/">
                <CustomImg3 src={Oxygeniu}></CustomImg3>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>

      <Timeline />
      <Container >
        <Grid container item justifyContent={"center"} alignItems={"center"} style={{height: "600px"}}>
          <img src={can} style={{ maxWidth: "100%", fontFamily: "Poppins"}} />
        </Grid>
      </Container>
      <Bottom />
    </>
  );
}

export default aboutUs;
