import { Container, Grid } from "@mui/material";
import React from "react";

import Jcanao from "../../../../assets/images/jcanao_white.svg";
import { SContainer, STypography } from "./style.jsx";

function Header() {
  return (
    <SContainer maxWidth={false}>
      <Container
        maxWidth="lg"
        sx={{ padding: { xs: "16px", lg: "none" }, height: "100%" }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ height: "15%" }}>
            <img src={Jcanao} alt="" width={200} />
          </Grid>
          <Grid item xs={12} sx={{ height: "85%", paddingTop: "70px" }}>
            <STypography>Política de Cookies</STypography>
          </Grid>
        </Grid>
      </Container>
    </SContainer>
  );
}

export default Header;
