import { Grid } from "@mui/material";
import React, { useState } from "react";
import {
  STypographyMobileTitle,
  SBox,
  SBoxExpanded,
  STypographyMobile,
  SButtonCardMobile,
} from "../../style.jsx";
import { Link } from "react-router-dom";

function Cardmobile() {
  let text = '{"Card":[' +
  `{"name":"Portos","text":"O forte crescimento deste setor leva a novas necessidades de adaptação. Mais do que nunca é necessário criar sistemas que suportem a ligação destes setores para que os mesmo continuem a crescer. Todos os Portos que não se tornem em SmartPorts serão lentamente deixados para trás.","cont":"0","lin":"https://canow.com.br/" },` +
  `{"name":"Transitários","text":"Uma solução que se foca em otimizar todos os processos transitários. Desde de reservas online, Tracking, integração com transportadoras, gestão de taxas e faturação automática e muito mais.","cont":"1","lin":"https://jcanao.com/transitarios" },` +
  `{"name":"Agentes Navegação","text":"Somos uma bússola para soluções marítimas, potencializando as operações dos Agentes de Navegação e superando desafios. Junte-se a nós na rota do sucesso!","cont":"2","lin":"https://jcanao.com/agentesnavegacao" },` +
  `{"name":"Operadores Portuários","text":"Descubra a solução personalizada que otimizará suas operações portuárias. Da administração estratégica ao controle de carga, elevamos seu terminal à excelência operacional.","cont":"0","lin":"https://jcanao.com/operadoresportuarios" },` +
  `{"name":"Operadores Logísticos","text":"Explore a solução sob medida que irá aprimorar suas operações logísticas. Desde a gestão estratégica até o controle de inventário, nós elevamos o seu operador logístico à excelência operacional.","cont":"1","lin":"https://jcanao.com/operadoreslogisticos" },` +
  `{"name":"Empresas","text":"Controle o seu negócio com eficiência através da nossa solução, desenhada para simplificar processos e automatizar tarefas. Com funcionalidades diferenciadoras, contribui para uma gestão eficaz, transmitindo confiança aos clientes e fornecedores.","cont":"2","lin":"http://jcanao.com/" },` +
  `{"name":"Setor Público","text":"O setor público está em constante evolução, e as administrações públicas precisam de se adaptar às novas exigências. As nossas soluções ajudam as organizações públicas a modernizar a sua administração, tornando-a mais eficiente, eficaz e transparente.","cont":"0","lin":"https://jcanao.com/setorpublico" }]}`;

  const Data = JSON.parse(text);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <Grid
      container
      sx={{
        display: "flex",
        gap: "16px",
      }}
    >
    {Object.keys(Data.Card).map((key, index) => (
      <>
      <Grid key={index} item xs={12}>
        <SBox>
          <STypographyMobileTitle onClick={() => handleExpand(index)}>{Data.Card[key].name}</STypographyMobileTitle>
          {expandedIndex === index && (
            <SBoxExpanded>
              <Grid container>
                <Grid item xs={12} align="center">
                  <STypographyMobile>
                    {Data.Card[key].text}
                  </STypographyMobile>
                  <Link style={{ textDecoration: "none" }} to={Data.Card[key].lin} target="_blank" >
                    <SButtonCardMobile>Saber mais</SButtonCardMobile>
                  </Link>
                </Grid>
              </Grid>
            </SBoxExpanded>
          )}
        </SBox>
      </Grid>
      </>
    ))}

    </Grid>
  );
}

export default Cardmobile;
