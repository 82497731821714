import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const BKImg = styled.img`
  && {
    position: absolute;
    width: 100%;
    height: 160%;
    max-height: 1200px;
    object-fit: cover;
    z-index: -1;
    @media only screen and (max-width: 599.5px){
      height: 160%;
    }
  }
`;

export const TiTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-weight: medium;
    font-size: 55px;
    color: white;

    text-align: center;
  }
`;

export const SuTiTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-weight: normal;
    font-size: 36px;
    color: white;

    margin-bottom: 32px;
  }
`;

export const PHGrid = styled(Grid)`
  &&{
    align-items: end;
    @media only screen and (max-width: 750px) {
      align-items: end;
    }
  }
`;

export const HGrid = styled(Grid)`
  &&{
    max-width: fit-content;
    @media only screen and (max-width: 750px) {
      max-width: 100%;
    }
  }
`;

export const CGrid = styled(Grid)`
  && {
    background: #1D68FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00FFD4;
    border: 2px solid #00FFD4;
    border-radius: 10px;

    padding: 32px;

    max-width: 502px;
    min-height: 223px;


    @media only screen and (max-width: 899.5px){
      max-width: 100%;
      min-height: 0;
    }
  }
`;

export const CSpan = styled.span`
  && {
    font-family: Poppins Medium;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;

  }
`;