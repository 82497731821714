import React from "react";
import { SButtonBack, SImg } from "../../style.jsx";
import BackSVG from "../../../../assets/icons/arrow.svg";

function BackButton() {
  return (
    <a href="/" style={{textDecoration:'none'}}>
    <SButtonBack disableRipple>
      <SImg src={BackSVG} alt="" />
      Voltar
    </SButtonBack>
    </a>
  );
}

export default BackButton;
