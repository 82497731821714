import { Box, Container } from "@mui/material";
import React from "react";
import { SBox, STypographyText, STypographyTitle, SButton3 } from "./style.jsx";
import BG from "../../../../assets/images/big_reworked_lighter.jpg";
import Arrow from "../../../../assets/icons/arrow.svg";
import BackButton from "../backbutton/BackButton.jsx";

function Main() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Container
      maxWidth="false"
      sx={{ background: `url(${BG})`}}
      disableGutters
    >
      <Container maxWidth="lg" sx={{position:'relative'}}>
      <BackButton />
        <SBox>
          <STypographyTitle>Termos de utilização do site</STypographyTitle>
          <STypographyText>
            A J.CANÃO, LDA com sede na Avenida de Cabo Verde, Lote 5, 4900-568 Viana do Castelo, registada na Conservatória do Registo Comercial de Viana do Castelo com o número único de Matricula e NIPC (número identificação pessoa coletiva) 501893776, com o capital social de € 290.000,00.
          </STypographyText>
          <STypographyText>
            A J.CANÃO, LDA pode a qualquer momento modificar, adicionar ou eliminar qualquer um dos Termos de Utilização do seu website através da atualização do conteúdo desta página. O Utilizador é assim aconselhado a visitar esta página regularmente.
          </STypographyText>
          <STypographyTitle>Aceitação dos termos de utilização</STypographyTitle>
          <STypographyText>
            Ao aceder a este website, o utilizador declara ter lido, compreendido e aceite os Termos de Utilização abaixo descritos, sem necessidade de qualquer ato ou consentimento posterior.
          </STypographyText>
          <STypographyTitle>Direitos de Autor</STypographyTitle>
          <STypographyText>
            Os textos, imagens, gráficos, downloads, sons, vídeos, animação, e todas as outras informações juntamente com a forma como são representadas graficamente no website, assim como a disposição e a estrutura do site (Materiais e Informação), estão todos sujeitos aos direitos de autor da J.CANÃO, LDA.
          </STypographyText>
          <STypographyText>
            A J.CANÃO, LDA não lhe concede licença ou direitos de propriedade sobre os Materiais e Informação, pelo que não tem o direito de reproduzi-los para qualquer outro fim que não a utilização pessoal.
          </STypographyText>
          <STypographyText>
            Nem o website nem partes do mesmo podem ser copiados, distribuídos, modificados ou colocados noutros sites, sem autorização prévia por escrito da J.CANÃO, LDA.
          </STypographyText>
          <STypographyTitle>Links para páginas de terceiros</STypographyTitle>
          <STypographyText>
            A J.CANÃO, LDA poderá disponibilizar links para páginas de terceiros no seu website. Estes sites não pertencem nem são operados ou controlados pela J.CANÃO, LDA e, por esta razão, a J.CANÃO, LDA não se responsabiliza pelo seu conteúdo. A utilização destes links é por sua conta e risco.
          </STypographyText>
          <STypographyTitle>Exclusão De Responsabilidade</STypographyTitle>
          <STypographyText>
            As informações existentes neste website são fornecidas pela J.CANÃO, LDA no estado em que se encontram e, até à máxima extensão permitida pela lei aplicável, são fornecidas sem qualquer tipo de garantia, expressa ou implícita. Embora as informações sejam tidas como corretas, podem incluir erros ou imprecisões, e poderão ser alteradas sem aviso prévio.
          </STypographyText>
          <STypographyText>
            A J.CANÃO, LDA não representa nem garante que as funções ou aplicações contidas neste website sejam contínuas ou não tenham erros. A J.CANÃO, LDA não garante que o servidor e/ou o website não contenham vírus ou outros componentes prejudiciais. A J.CANÃO, LDA não garante que os materiais deste website sejam adequados para utilização noutros locais. É proibido o acesso a materiais a partir de um território em que os conteúdos sejam ilegais.
          </STypographyText>
          <STypographyTitle>Descontinuação</STypographyTitle>
          <STypographyText>
           A J.CANÃO, LDA pode descontinuar, a qualquer momento, o website sem aviso prévio.
          </STypographyText>
          <STypographyTitle>Renúncia</STypographyTitle>
          <STypographyText>
            A J.CANÃO, LDA renuncia a qualquer responsabilidade por erros que possam ocorrer devido a erros do sistema ou falha (temporária ou permanente) do website, das aplicações ou de outras ferramentas. Até à máxima extensão possível ao abrigo da lei aplicável, a J.CANÃO, LDA não se responsabilizará por quaisquer danos resultantes da utilização, ou da impossibilidade de utilização, do website.
          </STypographyText>
          <STypographyTitle>Lei Aplicável</STypographyTitle>
          <STypographyText>
            Os presentes Termos de Utilização são regidos e elaborados de acordo com a legislação Portuguesa. Qualquer conflito ou divergência de interpretação dos mesmos será submetido ao Tribunal português competente.
          </STypographyText>
        </SBox>
        <Box
          maxWidth="lg"
          sx={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <SButton3 disableRipple={true} onClick={handleClick}>
            <img
              src={Arrow}
              alt=""
              width={20}
              style={{ transform: "rotate(180deg)" }}
            ></img>
          </SButton3>
        </Box>
      </Container>
    </Container>
  );
}

export default Main;
