import React, { useRef, useState } from "react";
import {
  SLabel,
  SInput,
  SButtonSend,
  TiTypography,
  SuTiTypography,
  CGrid,
  CLabel,
  SButton,
  BKImg,
  FGrid,
  DContainer,
} from "./style.jsx";
import { CircularProgress, Grid, Hidden } from "@mui/material";
import Header from "../../header/Header.jsx";
import Close from "@mui/icons-material/Close.js";

import BK1 from "../../../assets/images/image_carreiras.jpg";

import up from "../../../assets/icons/upload.svg";

import { toast } from "react-toastify";

function Section4() {

  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  // função javascript que faz envio post para API
  function sendEmailAPI(event, form) {
    // não deixa que a pagina html de um reload após fazer o submit
    event.preventDefault();

    // faz com que o loader apareça na tela
    setLoading(true);
    
    // URL da api para enviar requisição POST
    {/* 
      http://149.71.100.224:8181 
      comercial@jcanao.pt
    */}
    const URL = 'http://149.71.100.224:8181';

    // nome do arquivo (pdf)
    const fileName = form.nomecompleto.value.replace(" ", "-") + '-' + 'Curriculo.pdf';

    // objeto FormData para enviar como body de multipart/form
    const formData = new FormData();

    // capturando dados do formulario e atribuindo no objeto
    formData.append('mail', "comercial@jcanao.pt");
    formData.append('file', form.file.files[0], fileName);
    formData.append("nomecompleto", form.nomecompleto.value);
    formData.append("email", form.email.value);
    formData.append("telefone", form.telefone.value);
    formData.append("cargo", form.cargo.value);
    
    if (form.Ustupid.value.length === 0) {
    // enviando requisição ajax POST com FETCH API
    fetch(URL, {
      method: 'POST',
      body: formData
    })
    
    // caso sucesso (then) faz com o loarder se esconda e mostre um alerta com sucesso
    .then(function (response) {
        setLoading(false);
        toast.success("CURRICULO ENVIADO COM SUCESSO!");
    })
    // caso erro (catch) faz com o loarder se esconda e mostre um alerta com erro
    .catch(function (err) {
        setLoading(false);
        toast.error("ERRO AO ENVIAR CURRICULO: " + err);
    });
    }
  }


  const [ clea, setClea ] = useState("none");

  const displayFileName = () => {
    setClea("flex");
    const input = fileInputRef.current;
    var fileName = "No file selected";
    if (input.files && input.files.length > 0) {
      fileName = "";
      for (var i = 0; i < input.files.length; i++) {
        fileName += input.files[i].name + ", ";
      }
      fileName = fileName.slice(0, -2);
    }
    document.getElementById("selectedFileName").textContent = fileName;
  };

  const clearFile = () => {
    setClea("none");
    const input = fileInputRef.current;
    input.value = null;
    document.getElementById("selectedFileName").textContent = "";
  }

  return (
    <>
      <Header />
      <BKImg src={BK1} />
      <DContainer sx={{}}>
        <TiTypography>Junte-se à nossa equipa!</TiTypography>
        <SuTiTypography>Candidate-se já</SuTiTypography>
        <FGrid
          container
          item
          xs={12}
          md
        >
          <form id="form" onSubmit={(event) => sendEmailAPI(event, event.target)} enctype="multipart/form-data">
          <Grid item xs={12} >
            <SLabel>Nome*</SLabel>
            <SInput
              required
              disableUnderline={true}
              type="text"
              id="nomecompleto"
              name="nomecompleto"
              placeholder={"nome completo"}
            />
          </Grid>
          <Grid container item xs={12} style={{marginTop:"16px"}} gap={"21px"} justifyContent={"space-between"}>
            <Grid item xs={12} sm={8}>
              <SLabel>Email*</SLabel>
              <SInput
                required
                disableUnderline={true}
                type="email"
                id="email"
                name="email"
                placeholder={"nome@email.com"}
              />
            </Grid>
            <Grid item xs={12} sm >
              <SLabel>Contacto</SLabel>
              <SInput
                disableUnderline={true}
                type="number"
                id="telefone" 
                name="telefone"
                placeholder={"número"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop:"16px"}}>
            <SLabel>Cargo</SLabel>
            <SInput
              disableUnderline={true}
              type="text" id="cargo" name="cargo"
              placeholder={"cargo"}
            />
          </Grid>
          <Grid container item justifyContent={"space-between"} xs={12} style={{marginTop:"16px"}}>
            <Grid item xs={12}>
              <SLabel>Currículo*</SLabel>
            </Grid>
            <Grid item xs={3.8}>
              <input required type="file" id="file" name="file" ref={fileInputRef} style={{ display: "none" }} accept="application/pdf" onChange={displayFileName}/>
              <label style={{cursor: "pointer"}} htmlFor="file"><CLabel container item justifyContent={"center"} alignItems={"center"}><img src={up}/></CLabel></label>
            </Grid>
            <Grid item xs={7.7} >
              <CGrid container item xs={12} justifyContent={"space-between"} alignItems={"center"} >
              <span style={{ textWrap: "nowrap", color: "var(--blue)"}} id="selectedFileName"/>
              <SButton style={{display: `${clea}` }} onClick={clearFile}><Close style={{background: "grey", borderRadius: "30px", color: "white", maxHeight: "20px", maxWidth: "20px"}}/></SButton>
              </CGrid>
            </Grid>
          </Grid>
          <div style={{maxHeight:0, overflow: "hidden"}}>
            <input 
              disableUnderline={true}
              type="text" id="Ustupid" name="RUstupid"
              placeholder={"yes"}
            />
          </div>

          <Grid
            container
            item
            sx={{ display: "flex", gap: "21px", marginTop: "40px" }}
          >
            <Grid item xs={3}>
              <SButtonSend type="submit" >
                {loading ? (
                  <CircularProgress />
                ) : (
                  "Submeter"
                )}
              </SButtonSend>
            </Grid>
          </Grid>
          </form>
        </FGrid>
      </DContainer>
    </>
  );
}

export default Section4;
