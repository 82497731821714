import React from "react";
import { Routes, Route } from "react-router-dom";
import Services from "./pages/services/Services.jsx";
import Main from "./pages/main/Main.jsx";
import Error from "./pages/error/404.jsx";
import Consulting from "./pages/consulting/Consulting.jsx";
import Privacy from "./pages/privacypoliicy/Privacy.jsx";
import PoliticadeCookies from "./pages/Política_de_Cookies/Pcookies.jsx";
import TermoseCondicoes from "./pages/Termos_e_Condicoes/Termos_e_Condicoes.jsx";
import AboutUs from "./pages/aboutUs/aboutUs.jsx";
import Carreiras from "./pages/carreiras/carreiras.jsx";

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Error />} />
        <Route path="/services" element={<Services />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<PoliticadeCookies />} />
        <Route path="/condicoes" element={<TermoseCondicoes />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/carreiras" element={<Carreiras />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
