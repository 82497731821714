import React, { useRef, useState } from "react";
import { BKImg, CGrid, CSpan, HGrid, PHGrid, SuTiTypography, TiTypography } from "./style.jsx";

import BK1 from "../../../assets/images/background_carreiras.jpg";

import line from "../../../assets/icons/Retangulo_2629.svg";

import { Container, Grid } from "@mui/material";

function Section5() {

  return (
    <>
      <BKImg src={BK1} />
      <Container sx={{ minHeight: "750px", marginTop: "150px" }}>
        <PHGrid container item xs={12} justifyContent={"center"} alignItems={"end"}>
          <Grid item xs={12} style={{maxWidth:"fit-content"}}><img src={line} /></Grid>
          <HGrid item xs={12}><TiTypography>Os nossos mindsets</TiTypography></HGrid>
          <Grid item xs={12} style={{maxWidth:"fit-content"}}><img src={line} style={{marginTop:"10px"}}/></Grid>
        </PHGrid>
        <Grid container item xs={12} justifyContent={"center"} marginTop={"69px"}>
        <Grid container item xs={12} sm={11} justifyContent={"space-between"} gap={6} rowGap={6}>
          <CGrid item xs={12} md={5.6}>
            <SuTiTypography>Candidate-se já</SuTiTypography>
            <CSpan>Incentivamos interações abertas, onde cada colaborador é encorajado a partilhar sugestões diretamente com o CEO, criando um ambiente propício para trocar ideias e perspetivas relevantes.</CSpan>
          </CGrid>
          <CGrid item xs={12} md={5.6}>
            <SuTiTypography>Ambition & Results</SuTiTypography>
            <CSpan>Focamo-nos num programa de Thought Leadership para promover o talento, experiência e paixão pela tecnologia dos nossos colaboradores.</CSpan>
          </CGrid>
          <CGrid item xs={12} md={5.6}>
            <SuTiTypography>Learning & Improve</SuTiTypography>
            <CSpan>Investimos na formação contínua dos nossos colaboradores, oferecendo workshops, formações técnicas e comportamentais, certificações externas financiadas e parcerias com entidades formadoras.</CSpan>
          </CGrid>
          <CGrid item xs={12} md={5.6}>
            <SuTiTypography>Caring & Sharing</SuTiTypography>
            <CSpan>Adoramos bons momentos de convívios, para celebrar conquistas, resultados e ocasiões festivas. Cada motivo de alegria é uma oportunidade para criar memórias inesquecíveis</CSpan>
          </CGrid>
        </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Section5;
