import { Box, Modal } from "@mui/material";
import React from "react";
import {
  Sspan,
  STypographyText,
  STypography,
  SBox,
  SButtonAccept,
} from "./style.jsx";
import { Link } from "react-router-dom";

function Message({ open, handleClose }) {
  return (
    <Modal open={open}> 
      <SBox>
        <STypography>Valorizamos a sua privacidade</STypography>
        <STypographyText>
          Apenas utilizamos cookies para aprimorar sua experiência de navegação.
          Ao clicar em "Aceitar", concorda com o uso de cookies.
        </STypographyText>
        <STypographyText>
          Verifique a nossa <Link style={{textDecoration:'none'}} to="/privacy"><Sspan>política de privacidade</Sspan>.</Link>
        </STypographyText>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            justifyContent: "center",
            marginTop: "12px",
          }}
        >
          <SButtonAccept disableRipple onClick={handleClose}>
            Aceitar
          </SButtonAccept>
          {/* <SButtonDeny disableRipple
            onClick={() => {
              window.location.href = "https://www.google.com/";
            }}
          >
            Recusar
          </SButtonDeny> */}
        </Box>
      </SBox>
    </Modal>

  );
}

export default Message;
