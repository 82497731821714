import { Button, Checkbox, Container, FormLabel, Grid, Input, Typography } from "@mui/material";
import styled from "styled-components";

export const STypographyTitle = styled(Typography)`
  && {
    font-weight: 600;
    font-family: Poppins SemiBold;
    font-size: 20px;
    color: var(--green-third);
    margin-bottom: 12px;
  }
`;

export const STypographyDesc = styled(Typography)`
  && {
    font-weight: 400;
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--white);
    margin-bottom: 6px;
    width: 100%;
  }
`;

export const STypographyDesc2 = styled(Typography)`
  && {
    font-weight: 400;
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--white);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      background: rgba(255,255,255,0.2);
    }
    @media only screen and (max-width: 1161px){
      justify-content: start;
    }
  }
`;

export const SContainer = styled(Container)`
  && {
    background: rgba(255, 255, 255, 0.2);
    margin-top: 32px;
  }
`;

export const STypography = styled(Typography)`
  && {
    font-weight: 600;
    font-family: Poppins SemiBold;
    font-size: 20px;
    color: var(--green-third);
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const GridContainer1deca2 = styled(Grid)`
  && {
    display: none;

    @media only screen and (max-width: 1000px) {
      display: flex;
      padding: 32px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      width: 100%;
      max-height: fit-content;
      border-radius: 3px;
      box-shadow: 0px 0px 6px var(--blue-third);
      background: var(--light);
      gap: 32px;

      @media only screen and (max-width: 700px) {
        row-gap: 0;
        justify-content: center;
      }
    }
  }
`;

export const GridContainer1 = styled(Grid)`
  && {
    padding: 32px;
    with: 823px;

    border-radius: 3px;
    box-shadow: 0px 0px 6px var(--orange-third);
    background: var(--light);
    margin-bottom: 48px;
    gap: 32px;
    
    @media (max-width: 900px) {
      with: 100%;
      margin-bottom: 64px;
    }
  }
`;

export const GridContainer2 = styled(Grid)`
  && {
    row-gap: 1px;
    padding-left: 30px;
    display: flex;
    width: 200px;
    height: max-content;
    justify-content: start;
    @media only screen and (max-width: 899.5px){
      justify-content: space-between;
      width: 100%;
      padding-left: 0;
    }
  }
`;

export const GridContainer1_2 = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    padding-right: 30px;
    @media only screen and (max-width: 1000px){
      display: none;
    }
  }
`;

export const AGrid = styled(Grid)`
  && {
    @media only screen and (min-width: 1161px){
      min-height: 50px;
      &.ga1{
        max-width: 6.67%;
      }
      &.ga2{
        max-width: 15%;
      }
      &.ga3{
        max-width: 18.33%;
      }
      &.ga4{
        max-width: 16.67%;
      }
      &.ga5{
        max-width: 17.50%;
      }
      &.ga6{
        max-width: 25.83%;
      }
    }
    min-height: 35px;
  }
`;


export const SButtonMedia= styled(Button)`
  && {
    font-Family: Poppins Medium;
    margin-bottom: 16px;
    border-radius: 8px;
    background: var(--blue);
    max-width: 244px;
    height: 66px;
    color: var(--light);
    font-size: 20px;
    font-weight: medium;
    align-self: center;
    text-transform: none;
    &:hover {
      background: var(--blue-second);
    }
    @media only screen and (max-width: 1100px) {
      font-size: 1.8vw;
    }
    @media only screen and (max-width: 1000px) {
      font-size: 24px;
      width: 45%;
      max-width: 45%;
      min-width: 244px;
    }
    @media (max-width: 200px) {
      margin-top: 16px;
      width: 100%;
    }
  }
`;

export const BoxContactoText1 = styled(Typography)`
  && {
    font-Family: Poppins Medium;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 40px;
    opacity: 1;
    text-align: center;
    align-self: center;

    transition: .01s;

    @media only screen and (max-width: 1150px){
      font-size: 1.85vw;
    }
  }
`;


export const BoxContactoText2 = styled(Typography)`
  && {
    font-Family: Poppins Medium;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    color: var(--gray-secondary);
    opacity: 1;
    text-align: center;
    align-self: center;
  }
`;


export const SLabel = styled(FormLabel)`
  && {
    font-Family: Poppins Medium;
    font-size: 20px;
    font-weight: normal;
    color: var(--blue-secondary);
    text-align: left;
  }
`;

export const SInput = styled(Input)`
  && {
    width: 100%;
    height: 36px;
    padding-left: 12px;
    margin: 0;
    margin-bottom: 2px;
    border-radius: 5px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 3px #5151515C;
    transition: .5;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }

    &:hover{
      border: 3px solid var(--blue);
    }
  }
`;


export const SButtonSend = styled(Button)`
  && {
    font-Family: Poppins Medium;
    border-radius: 5px;
    background: var(--blue);
    width: 100%;
    height: 42px;
    max-width: 120px;
    box-shadow: 0px 1px 3px #00000029;

    color: var(--light);
    font-size: 20px;
    font-weight: medium;
    text-transform: none;

    &:hover {
      background: var(--green);
      color: var(--blue);
    }
    
    @media only screen and (max-width: 1050px) and (min-width: 900px) {
      font-size: 1.8vw;
    }
    @media only screen and (max-width: 500px) {
      font-size: 3.6vw;
    }
  }
`;


export const SCheckbox = styled(Checkbox)`
  && {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    color: var(--light);
    border: 2px solid var(--blue);
    margin-right: 10px;
  }
`;


export const STypographyCheck = styled(Typography)`
  && {
    font-Family: Poppins Medium;
    font-size: 14px;
    font-weight: normal;
    color: var(--blue-secondary);
    
    @media only screen and (max-width: 600px) {
      font-size: 3.2vw;
    }
  }
`;

export const OGrid = styled(Grid)`
  && {
    max-width: 100%;

    @media only screen and (min-width: 720px) {
      max-width:33.33%;
    }
    &&.mand{
      @media only screen and (min-width: 720px) {
        max-width: fit-content;
      }
    }
  }
`;

export const COGrid = styled(Grid)`
  && {
    max-width: 100%;

    @media only screen and (min-width: 720px) and (max-width: 899.5px) {
      max-width:33.33%;
    }
  }
`;
