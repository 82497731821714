import React, { useState } from "react";
import Certifications from "../../assets/images/certificacao_cognita.png";
import {
  SBox,
  SBox2,
  STypographyTitle,
  STypographyTitle2,
  STypographyText,
  AGrid,
  BGrid,
  ARemoveIcon,
  AAddIcon,
} from "./style.jsx";
import Excello from "../../assets/downloadFile/Excello2020.pdf";
import Cognita from "../../assets/downloadFile/Cognita2020.jpg";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function BoxCognita() {
  const [active, setActive] = useState("");

  const handleToggleExpand = () => {
    if (active === "active") {
      setActive("");
    } else {
      setActive("active");
    }
  };

  return (
    <SBox onClick={handleToggleExpand}>
      <AGrid className={active} container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
        <STypographyTitle>#Projetos Financiados</STypographyTitle> 
        <AAddIcon className={active} />
        <ARemoveIcon className={active} />
      </AGrid>
      <BGrid className={active} container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
        <a href={Cognita} target="_blank" style={{ textDecoration: "none" }}>
          <SBox2>
            <STypographyTitle2>Projeto Cognita</STypographyTitle2>
            <STypographyText>
              Programa Operacional Regional do Norte - Norte 2020
            </STypographyText>
            <img width={230} src={Certifications} alt="" />
          </SBox2>
        </a>
        <a href={Excello} target="_blank" style={{ textDecoration: "none" }}>
          <SBox2 sx={{ marginTop: "16px" }}>
            <STypographyTitle2>Projeto Excello</STypographyTitle2>
            <STypographyText>
              Programa Operacional Regional do Norte - Norte 2020
            </STypographyText>
            <img width={230} src={Certifications} alt="" />
          </SBox2>
        </a>
      </BGrid>
    </SBox>
  );

}

export default BoxCognita;
