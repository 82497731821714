import { Container, Grid } from "@mui/material";
import React from "react";
import Error404 from "../../assets/images/erro404_800x800.gif";
import {
  STypographyTitle,
  STypographyText,
  SButton,
  ResponsiveImage,
} from "./style.jsx";
import Header from "../../components/header/Header.jsx";

function error() {
  const title = ["A página solicitada não foi encontrada."];
  const description = [
    "Isso pode acontecer devido aos seguintes motivos:",
    "A página ou o arquivo está desatualizado, renomeado, movido ou não existe.",
    "Você digitou o endereço incorretamente, como http://www.example.com/pgae.html em vez de http://www.example.com/page.html",
  ];
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container mt={8} sx={{ display: "flex", alignItems: "center" }}>
          <Grid item lg={7} md={7}>
            <ResponsiveImage src={Error404} />
          </Grid>
          <Grid
            item
            lg={5}
            md={5}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            {title.map((item, index) => (
              <STypographyTitle variant="p" key={index}>
                {item}
              </STypographyTitle>
            ))}

            {description.map((item, index) => (
              <STypographyText variant="p" key={index}>
                {item}
              </STypographyText>
            ))}
            <a href="/">
            <SButton disableRipple>Voltar</SButton>
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default error;
