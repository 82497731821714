import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import styled, { keyframes } from "styled-components";

export const ZETAGrid = styled(Grid)`
  &&{
    max-width: 50%;
    margin-top: ${({ marginTopSm }) => marginTopSm || '0'};

  @media only screen and (min-width: 1300px) {
    max-width:33%;
    margin-top: ${({ marginTopLg }) => marginTopLg || '0'};
  }
}
`;

const slideIn = keyframes`
from{
  transform: translateY(0%);

}to{
  transform: translateY(0%);
}
`;

export const SBoxCard = styled(Box)`
  height: 420px;
  width: 74px;
  border-radius: 8px;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -32px;
  left: -40px;
  transition: top 0.3s ease-in-out;
  animation: ${slideIn} 0.5s ease-in-out;
`;

export const TypographyBox = styled(Typography)`
  && {
    color: var(--green);
    font-size: 32px;
    font-weight: medium;
    font-family: Poppins Medium;
    text-align: center;
    white-space: nowrap;
  }
`;

export const STypographyCard = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: normal;
    font-family: Poppins Regular;
    color: var(--dark);
    opacity: 0;
    animation: ${slideIn} 0.5s ease-in-out;
  }
`;

export const SButtonCard = styled(Button)`
  && {
    width: 165px;
    height: 52px;
    border-radius: 8px;
    background: var(--white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--green-second);
    font-size: 20px;
    padding: 0;
    animation: ${slideIn} 0.5s ease-in-out;
    font-weight: 500;
    font-family: Poppins Medium;
    border: 2px solid var(--green);
    color: var(--green-third);
    text-transform: none;
    opacity: 0;
    &:hover {
      background: var(--green) 0% 0% no-repeat padding-box;
      border: 2px solid var(--green);
      color: var(--white);
    }
  }
`;

export const SButtonCardMobile = styled(Button)`
  && {
    z-index: 5;
    width: 165px;
    height: 52px;
    border-radius: 8px;
    background: var(--green-third) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--green-second);
    font-size: 20px;
    padding: 0;
    animation: ${slideIn} 0.5s ease-in-out;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--light);
    text-transform: none;
    margin-top: 16px;
    &:hover {
      background: var(--green) 0% 0% no-repeat padding-box;
      border: 2px solid var(--green);
      color: var(--white);
    }
  }
`;

export const SPaperCard = styled(Paper)`
  && {
    padding: 32px 32px 32px 62px;
    min-width: 265px;
    max-width: 265px;
    height: 355px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    animation: ${slideIn} 0.5s ease-in-out;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 6px var(--green-third);
    position: relative;
    &:hover {
      ${SBoxCard} {
        top: 0;
        transition: 0.3s ease-in-out;
        background: var(--green);
      }
      ${TypographyBox} {
        color: var(--white);
      }
      ${STypographyCard} {
        opacity: 1;
        transition: 0.3s ease-in-out;
      }
      ${SButtonCard} {
        opacity: 1;
        transition: 0.3s ease-in-out;
      }
      background: rgba(255, 255, 255, 1);
    }
  }
  &.fill{
    opacity: 0;
    z-index: -2;
  }
`;

export const STypographyMobileTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 28px;
    color: var(--green);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;

    cursor: pointer;
  }
`;

export const SBox = styled(Box)`
  background: var(--white);
  border-radius: 8px;
`;

export const SBoxExpanded = styled(Box)`
  z-index: 5;
  background: var(--white);
  padding: 22px;
  font-family: Poppins Regular;
  font-size: 16px;
  border-radius: 8px;
`;

export const STypographyMobile = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--dark);
    text-align: left;
  }
`;
