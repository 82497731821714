import { Container, Grid } from "@mui/material";
import React from "react";
import jcanao from "../../../../assets/images/jcanao_white.svg";
import { SContainer, STypography } from "../../style";

function Header() {
  return (
    <SContainer maxWidth={false}>
      <Container maxWidth="lg" sx={{ padding: "0px", height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ height: "15%" }}>
            <img src={jcanao} alt="" width={200} />
          </Grid>
          <Grid item xs={12} sx={{ height: "85%", paddingTop: "70px" }}>
            <STypography>Design & Development</STypography>
          </Grid>
        </Grid>
      </Container>
    </SContainer>
  );
}

export default Header;
