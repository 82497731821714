import { Container, ListItem, Typography, Button } from "@mui/material";
import styled from "styled-components";
import Bg from "../../assets/images/cover-des-dev.jpg";
import Bg2 from "../../assets/images/big_reworked_lighter.jpg";

export const SContainer = styled(Container)`
  background: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
`;

export const SContainer2 = styled(Container)`
  background: url(${Bg2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 36px;
  @media (max-width: 768px) {
      margin-top: calc(64px - 16px);
      position: relative;
      padding-top:64px;
    }
`;

export const STypography = styled(Typography)`
  && {
    color: var(--white);
    font-size: 76px;
    font-weight: 400;
    font-family: Poppins Regular;
    width: 511px;
    @media (max-width: 600px) {
      width: 100%;
      font-size: 48px;
    }
  }
`;

export const SContainerInfo = styled(Container)`
&&{
  padding: 64px 64px;
  border: 2px solid var(--green-third);
  background: var(--blue-sixth);
  border-radius: 10px;
  box-shadow: 0px 0px 6px var(--green-third);
  margin-bottom:64px;
  @media (max-width: 768px) {
    padding: 32px;
  }
`;

export const STypographyTitle = styled(Typography)`
  && {
    font-size: 56px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--green-third);
  }
`;

export const STypographyText = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--white);
    max-width: 500px;
    margin-top: 30px;
  }
`;

export const STypographyList = styled(ListItem)`
  && {
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins Regular;
    color: var(--green-third);
    display: list-item;
  }
`;

export const SButton = styled(Button)`
  && {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 3px 6px var(--gray);
    background: var(--white) padding-box;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--blue-seventh);
    text-transform: none;
    margin-top: 64px;
    padding: 12px 32px;
    &:hover {
      background: var(--green-third);
    }
  }
`;

export const SButton2 = styled(Button)`
  && {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 3px 6px var(--gray);
    background: var(--white) padding-box;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--blue-seventh);
    text-transform: none;
    margin-top: 64px;
    margin-bottom: 90px;
    padding: 12px 32px;
    &:hover {
      background: var(--green-third);
    }
  }
`;

export const SButton3 = styled(Button)`
  && {
    border: 3px solid var(--green-third);
    border-radius: 8px;
    background: rgba(var(--green-third));
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    margin-bottom: 33px;
    &:hover {
      background: var(--white);
    }
  }
`;

export const SButtonBack = styled(Button)`
  && {
    position: sticky;
    top: -2.8%;
    transform: translateY(105%);
    left: 1650px;
    width: 122px;
    height: 46px;
    background: var(--white);
    border-radius: 5px;
    border: none;
    color: var(--blue);
    text-transform: none;
    font-weight: 500;
    font-family: Poppins Medium;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    &:hover {
      background: var(--green-third);
    }
    @media (max-width: 768px) {
      position: absolute;
      left: 16px;
      top: -60px;
    }
  }
`;

export const SImg = styled.img`
  transform: rotate(90deg);
  filter: invert(60%) sepia(63%) saturate(3000%) hue-rotate(215deg)
    brightness(103%) contrast(80%);
  width: 16px;
`;
