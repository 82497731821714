import React from "react";
import Bottom from "../../components/clientes/Clientes.jsx";
import Section1 from "../../components/Sections/Section1/section1.jsx";
import Section2 from "../../components/Sections/Section2/section2.jsx";
import Section3 from "../../components/Sections/Section3/section3.jsx";

function Main() {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Bottom />
    </>
  );
}

export default Main;
