import { Grid } from "@mui/material";
import React from "react";
import { SGrid, SText, SImg, SImg2 } from "./style.jsx";
import TopLeft from "../../assets/images/top_left.svg";
import BottomRight from "../../assets/images/down_right.svg";

function contactText() {
  return (
    <>
    <Grid container item sx={{ display: "flex", justifyContent: "center" }}>
      <SGrid item xs={12} lg={7}>
        <SImg src={TopLeft} atl="" />
        <SText>
          Contacte-nos e descubra o que podemos fazer pos si para melhorar o seu
          negócio.
        </SText>
        <SImg2 src={BottomRight} atl="" />
      </SGrid>
    </Grid>
    </>
  );
}

export default contactText;
