import { Button, Checkbox, Container, FormLabel, Grid, IconButton, Input, Typography } from "@mui/material";
import styled from "styled-components";

export const DContainer = styled(Container)`
  && {
    min-height: 800px;
    @media only screen and (max-width: 772.5px){
      min-height: 900px;
    }
  }
`;

export const BKImg = styled.img`
  && {
    position: absolute;
    top: 0;
    width: 100%;
    height: 160%;
    max-height: 1200px;
    object-fit: cover;
    object-position: right top;
    z-index: -1;
    transition: 2s;
    @media only screen and (max-width: 899.5px){
      object-position: left;
    }
    @media only screen and (max-width: 599.5px){
      height: 160%;
    }
  }
`;

export const TiTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-weight: medium;
    font-size: 55px;
    color: white;

    padding-top: 100px;
    margin: 64px 0px;
  }
`;

export const SuTiTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-weight: normal;
    font-size: 36px;
    color: white;

    margin-bottom: 32px;
  }
`;

export const STypographyTitle = styled(Typography)`
  && {
    font-weight: 600;
    font-family: Poppins SemiBold;
    font-size: 20px;
    color: var(--green-third);
    margin-bottom: 12px;
  }
`;

export const STypographyDesc = styled(Typography)`
  && {
    font-weight: 400;
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--white);
    margin-bottom: 6px;
    width: 100%;
  }
`;

export const STypographyDesc2 = styled(Typography)`
  && {
    font-weight: 400;
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--white);
    height: 50px;
    display: flex;
    align-items: center;
  }
`;

export const SContainer = styled(Container)`
  && {
    background: rgba(255, 255, 255, 0.2);
    margin-top: 32px;
  }
`;

export const STypography = styled(Typography)`
  && {
    font-weight: 600;
    font-family: Poppins SemiBold;
    font-size: 20px;
    color: var(--green-third);
    text-align: left;
    height: 50px;
    display: flex;
    align-items: center;
  }
`;

export const FGrid = styled(Grid)`
  && {
    gap: 21px;
    row-gap: 8px;
    justify-content: space-between;
    max-width: 502px;
    
    @media (max-width: 899.5px) {
      max-width: none;
    }
  }
`;

export const GridContainer1 = styled(Grid)`
  && {
    padding: 32px;
    with: 823px;

    border-radius: 3px;
    box-shadow: 0px 0px 6px var(--orange-third);
    background: var(--light);
    margin-bottom: 48px;
    gap: 32px;
    
    @media (max-width: 900px) {
      with: 100%;
      margin-bottom: 64px;
    }
  }
`;

export const GridContainer2 = styled(Grid)`
  && {
    row-gap: 1px;
    padding-left: 30px;
    display: flex;
    width: 200px;
    height: max-content;
    justify-content: start;
    @media only screen and (max-width: 899.5px){
      justify-content: space-between;
      width: 100%;
      padding-left: 0;
    }
  }
`;

export const GridContainer1_2 = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    padding-right: 30px;
    @media only screen and (max-width: 1000px){
      display: none;
    }
  }
`;

export const CGrid = styled(Grid)`
  && {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    max-width: 100%;
    overflow-x: scroll;
    height: 48px;
    border-radius: 5px;
    padding: 0px 40px 0px 8px;

    -ms-overflow-style: none; 
    scrollbar-width: none;
  }
  &&::-webkit-scrollbar {
    display: none;
  }
`;

export const SButton = styled(IconButton)`
  && {
    position: absolute;
    margin-left: 285px;

    height: 26px;
    width: 26px;

    @media only screen and (max-width: 530px){
      margin-left: 0px;
      right: 16px;
    }
  }
`;

export const CLabel = styled(Grid)`
  && {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    max-width: 100%;
    overflow-x: scroll;
    height: 48px;
    border-radius: 5px;
    padding: 0px 8px;

    -ms-overflow-style: none; 
    scrollbar-width: none;
  }
  &&::-webkit-scrollbar {
    display: none;
  }
`;

export const SButtonMedia= styled(Button)`
  && {
    font-Family: Poppins Medium;
    margin-bottom: 16px;
    border-radius: 8px;
    background: var(--blue);
    max-width: 224px;
    height: 66px;
    color: var(--light);
    font-size: 20px;
    font-weight: medium;
    align-self: center;
    text-transform: none;
    transition: .2s;
    &:hover {
      background: var(--green);
      color: var(--blue);
    }
    @media (max-width: 1100px) {
      font-size: 1.8vw;
    }
    @media (max-width: 200px) {
      margin-top: 16px;
      width: 100%;
    }
  }
`;

export const BoxContactoText1 = styled(Typography)`
  && {
    font-Family: Poppins Medium;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 40px;
    opacity: 1;
    text-align: center;
    align-self: center;

    transition: .01s;

    @media only screen and (max-width: 1150px){
      font-size: 1.85vw;
    }
  }
`;


export const BoxContactoText2 = styled(Typography)`
  && {
    font-Family: Poppins Medium;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    color: var(--gray-secondary);
    opacity: 1;
    text-align: center;
    align-self: center;
  }
`;


export const SLabel = styled(FormLabel)`
  && {
    font-Family: Poppins Medium;
    font-size: 20px;
    font-weight: normal;
    color: var(--white);
    text-align: left;
  }
`;

export const SInput = styled(Input)`
  && {
    width: 100%;
    height: 48px;
    padding-left: 12px;
    margin: 0;
    margin-bottom: 2px;
    border-radius: 5px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    transition: .5;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }

    &:hover{
      border: 3px solid var(--orange);
    }
  }
`;


export const SButtonSend = styled(Button)`
  && {
    font-Family: Poppins Medium;
    border-radius: 5px;
    background: var(--green);
    width: 100%;
    height: 48px;
    min-width: 131px;
    max-width: 131px;
    box-shadow: 0px 1px 3px #00000029;

    color: var(--blue);
    font-size: 20px;
    font-weight: medium;
    text-transform: none;

    &:hover {
      background: var(--white);
      color: var(--blue);
    }
    
  }
  & CircularProgress {
    size= 35;
    color: var(--white);
    &:hover {
      color: var(--blue);
    }
  }
`;

export const SCheckbox = styled(Checkbox)`
  && {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    color: var(--light);
    border: 2px solid var(--blue);
    margin-right: 10px;
  }
`;


export const STypographyCheck = styled(Typography)`
  && {
    font-Family: Poppins Medium;
    font-size: 16px;
    font-weight: normal;
    color: var(--blue-secondary);
    
    @media only screen and (max-width: 600px) {
      font-size: 3.2vw;
    }
  }
`;