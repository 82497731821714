import { Divider, Typography } from "@mui/material";
import styled from "styled-components";

export const STypography = styled(Typography)`
  && {
    font-size: 56px;
    font-weight: 500;
    font-family: Poppins Medium;
    color: var(--white);
    text-shadow: 0px 1px 6px var(--white-fourth);
    @media (max-width: 600px) {
      font-size: 26px;
    }
  }
`;

export const SDivider = styled(Divider)`
  && {
    max-width: 260px;
    height: 6px;
    background: var(--green-third);
    border-radius: 3px;
    box-shadow: 0px 3px 6px var(--green-third);
  }
`;
