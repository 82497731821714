import React from "react";
import { Grid } from "@mui/material";
import {
  STypographyTitle2,
  STypographyText2,
  SButtonDownload,
  SContainer2,
  STypographyDownload,
  STypographyDownload2,
  STextDownload,
} from "../../style.jsx";
import DownloadFile from "../../../../assets/downloadFile/4PORT.pdf";

function Box() {
  return (
    <SContainer2 maxWidth="lg">
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          lg={10}
          sx={{
            padding: {xs:'32px', md:'64px'},
            background: "var(--blue-sixth)",
            border: "2px solid var(--green-third)",
            borderRadius: "10px",
            boxShadow: "0px 0px 9px var(--green-third)",
          }}
        >
          <Grid container>
            <Grid item xs={12} md>
              <STypographyTitle2>Estudos & Benchmarks</STypographyTitle2>
              <STypographyText2>
                Realizamos estudos e benchmarks, internos, competitivos,
                funcionais, cooperativos. Reconheça onde se situa atualmente,
                que pontos necessita reforçar e possíveis formas de o fazer.
                Além disso ganhe conhecimento para superar os pontos fortes da
                concorrência. Identifique oportunidades e afirme-se no mercado.
              </STypographyText2>
            </Grid>
            <Grid
              item
              xs={12}
              md
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
                marginTop:{xs:'64px', md:'0px'}
              }}
            >
              <STypographyDownload>Último estudo realizado</STypographyDownload>
              <STypographyDownload2>
                Projeto 4PORT - Smart Ports
              </STypographyDownload2>
              <SButtonDownload disableRipple>
                <STextDownload href={DownloadFile} download>
                  Download
                </STextDownload>
              </SButtonDownload>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SContainer2>
  );
}

export default Box;
