import React from "react";
import Arrow from "../../assets/icons/arrow.svg";
import { SButton3 } from "./style.js";
import { Container, Grid } from "@mui/material";

import BG from "../../assets/images/bottom.jpg";
import BG2 from "../../assets/images/Grupo_3474/Grupo_3474.png";

function Clientes() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Grid container item xs={12} style={{ backgroundImage: `url(${BG})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
      <Container
        maxWidth="false"
        disableGutters
        sx={{
          display: { xs: "none", lg: "flex" },
          flexDirection: "column",
          justifyContent:'end',
          alignItems:'center',
          position: "relative",
          zIndex: "1",
        }}
      >
        <img src={BG2} style={{margin: "265px 0px 235px 0px", width: "100%"}}/>

        <SButton3 disableRipple={true} onClick={handleClick}>
          <img
            src={Arrow}
            alt=""
            width={20}
            style={{ transform: "rotate(180deg)" }}
          ></img>
        </SButton3>
      </Container>
    </Grid>
  );
}

export default Clientes;
