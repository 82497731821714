import { FormControl, InputBase, InputLabel, MenuItem, Select, createTheme } from '@mui/material';
import React, { useState } from 'react';
import { SMenuItem } from './style';

const theme = createTheme({
	palette: {
		primary: {
		main: '#ffffff'
		}
	},
	components: {
		MuiOutlinedInput: {
		styleOverrides: {
			notchedOutline: {
			borderColor: '#ff0000', // Change this to the desired color
			},
		},
		},
	},
});

function HeaderButtonLang() {
	const [selectedValue, setSelectedValue] = useState(0); // State to store the selected value

	const handleChange = (event) => {
		setSelectedValue(event.target.value); // Update the selected value when changed
	};

	return (
		<>
			<FormControl sx={{ m: 1 }} variant="outlined">
				<Select
				style={{color: 'white', border: "1px solid #ffffff", borderRadius: "5px"}}
				theme={theme}
				value={selectedValue}
				onChange={handleChange}
				input={<InputBase />}
				SelectDisplayProps={{
					style: { padding: "10px 20px" }, // Remove the padding to the right of the select input
				}}
				IconComponent={""} 
				>
				<SMenuItem value={0} sx={{justifyContent: 'center'}}>PT</SMenuItem>
				<SMenuItem value={1} sx={{justifyContent: 'center'}}>EN</SMenuItem>
				<SMenuItem value={2} sx={{justifyContent: 'center'}}>DE</SMenuItem>
				</Select>
			</FormControl>
		</>
	);
}

export default HeaderButtonLang;
