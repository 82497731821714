import React from "react";

import Section4 from "../../components/Sections/Section4/section4.jsx";
import Section5 from "../../components/Sections/Section5/section5.jsx";

function Carreiras() {
  return (
    <>
      <Section4 />
      <Section5 />
    </>
  );
}

export default Carreiras;
