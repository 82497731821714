import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { IGrid, Ia, SContainer, Sp } from "./style";

export default function MainDropMenu({ closeDropdown, activeDropdown }) {

  const [pose, setPose] = useState(700);

  function getElementXPosition(elementId) {
    var element = document.getElementById(elementId);
    if (element) {
      setPose(element.offsetLeft);
    } else {
      console.error("Element with ID '" + elementId + "' not found.");
      return null;
    }
  }

  useEffect(() => {
    function handleResize() {
      getElementXPosition("btSoluções");
    }

    getElementXPosition("btSoluções");

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SContainer
      open={activeDropdown}
      onClose={closeDropdown}
      maxWidth={"false"}
      sx={{
        borderRadius: "0px 0px 8px 8px",
        
        borderTop: "1px solid var(--green2)",
        background: "var(--blue) 0% 0% no-repeat padding-box",
        height: activeDropdown === 2 ? "380px" : "205px",
        position: "absolute",
        padding: "32px",
        left: `${pose}px`,
        top: "80px",
        width:"575px",
      }}
    >
      <Grid Container item xs={12} display={"flex"} justifyContent={"space-around"}>
        <IGrid container item xs rowGap={"8px"} style={{boxShadow: "6px 0px 1px -5px var(--green2)",width: "263px", paddingRight: "32px", marginRight: "32px"}} >
          <IGrid item xs={12}><Ia href="https://canow.com.br/" target="_blank" ><Sp style={{}}>Portos</Sp></Ia></IGrid>
          <IGrid item xs={12}><Ia href="https://jcanao.com/transitarios " target="_blank" ><Sp style={{}}>Transitários</Sp></Ia></IGrid>
          <IGrid item xs={12}><Ia href="https://jcanao.com/agentesnavegacao" target="_blank" ><Sp style={{}}>Agentes de Navegação</Sp></Ia></IGrid>
          <IGrid item xs={12}><Ia href="https://jcanao.com/operadoresportuarios" target="_blank" ><Sp style={{}}>Operadores Portuários</Sp></Ia></IGrid>
        </IGrid>
        <IGrid container item xs rowGap={"8px"} >
          <IGrid item xs={12}><Ia href="https://jcanao.com/operadoreslogisticos" target="_blank" ><Sp style={{}}>Operadores Logísticos</Sp></Ia></IGrid>
          <IGrid item xs={12}><Ia href="http://jcanao.com/" target="_blank" ><Sp style={{}}>Empresas</Sp></Ia></IGrid>
          <IGrid item xs={12}><Ia href="https://jcanao.com/setorpublico" target="_blank" ><Sp style={{}}>Setor Público</Sp></Ia></IGrid>
        </IGrid>
      </Grid>
    </SContainer>
  );
}
