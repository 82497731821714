import React, { useEffect, useState } from "react";
import Phone from "../../../assets/icons/phone.svg";
import { SButtonContactFixed, STypographyBtn } from "../style.jsx";
import Modalcontact from "./ContactFormModal";

export default function Maincontactbtn() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const triggerHeight = 400;

      if (scrollY > triggerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      {showButton && (
        <SButtonContactFixed disableRipple onClick={handleOpen}>
          <img src={Phone} style={{ width:"30px", height: "30px"}} alt="" />
          <STypographyBtn>Contactar</STypographyBtn>
        </SButtonContactFixed>
      )}
      {openModal && (
        <Modalcontact openModal={openModal} handleClose={handleClose} />
      )}
    </>
  );
}
