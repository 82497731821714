import React, { useState } from "react";
import Header from "./components/header/Header.jsx";
import InfoBox from "./components/wrapperbox/Box.jsx";
import Boxs from "./components/box/Box.jsx";
import Fullbox from "./components/fullbox/Box.jsx";
import { SContainer2, SButton2, SButton3 } from "./style.jsx";
import { Box } from "@mui/material";
import Arrow from "../../assets/icons/arrow.svg";
import BackButton from "./components/backbutton/BackButton.jsx";
import ModalContact from "../../components/contact/components/ContactFormModal";

function Consulting() {

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Header />
      <SContainer2 maxWidth={false}>
        <BackButton />
        <InfoBox />
        <Boxs />
        <Fullbox />
        <Box
          maxWidth="lg"
          sx={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <SButton2 onClick={handleOpen} disableRipple={true}>Contacte-nos</SButton2>
          {openModal && (
        <ModalContact openModal={openModal} handleClose={handleClose} />
          )}
        </Box>
        <Box
          maxWidth="lg"
          sx={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <SButton3 disableRipple={true} onClick={handleClick}>
            <img
              src={Arrow}
              alt=""
              width={20}
              style={{ transform: "rotate(180deg)" }}
            ></img>
          </SButton3>
        </Box>
      </SContainer2>
    </>
  );
}

export default Consulting;
